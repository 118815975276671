/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type NewTokenInput = {
    value: number;
    twitchUsername: string;
    clientMutationId?: string | null;
};
export type RequestTokenPageNewTokenMutationVariables = {
    input: NewTokenInput;
};
export type RequestTokenPageNewTokenMutationResponse = {
    readonly newToken: {
        readonly id: string | null;
        readonly user: {
            readonly availableTokens: ReadonlyArray<{
                readonly value: number;
                readonly formattedValue: string;
                readonly createdAt: number;
            }>;
        };
    } | null;
};
export type RequestTokenPageNewTokenMutation = {
    readonly response: RequestTokenPageNewTokenMutationResponse;
    readonly variables: RequestTokenPageNewTokenMutationVariables;
};



/*
mutation RequestTokenPageNewTokenMutation(
  $input: NewTokenInput!
) {
  newToken(input: $input) {
    id
    user {
      availableTokens {
        value
        formattedValue
        createdAt
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattedValue",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestTokenPageNewTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NewTokenPayload",
        "kind": "LinkedField",
        "name": "newToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RequestToken",
                "kind": "LinkedField",
                "name": "availableTokens",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestTokenPageNewTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NewTokenPayload",
        "kind": "LinkedField",
        "name": "newToken",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RequestToken",
                "kind": "LinkedField",
                "name": "availableTokens",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4e4133c40372da8b5e1e91cc02a8d8cd",
    "id": null,
    "metadata": {},
    "name": "RequestTokenPageNewTokenMutation",
    "operationKind": "mutation",
    "text": "mutation RequestTokenPageNewTokenMutation(\n  $input: NewTokenInput!\n) {\n  newToken(input: $input) {\n    id\n    user {\n      availableTokens {\n        value\n        formattedValue\n        createdAt\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'af3bf0ae8d1e289e3fb3be8bcac7721a';
export default node;
