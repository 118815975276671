/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SongListManagementPageQueryVariables = {};
export type SongListManagementPageQueryResponse = {
    readonly me: {
        readonly isAdmin: boolean;
    } | null;
};
export type SongListManagementPageQuery = {
    readonly response: SongListManagementPageQueryResponse;
    readonly variables: SongListManagementPageQueryVariables;
};



/*
query SongListManagementPageQuery {
  me {
    isAdmin
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SongListManagementPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SongListManagementPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a3552e348a493459877a11371e8670cb",
    "id": null,
    "metadata": {},
    "name": "SongListManagementPageQuery",
    "operationKind": "query",
    "text": "query SongListManagementPageQuery {\n  me {\n    isAdmin\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '63b122bfcceb4365c0e463f054227621';
export default node;
