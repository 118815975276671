/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SongListPageQueryVariables = {};
export type SongListPageQueryResponse = {
    readonly me: {
        readonly availableTokens: ReadonlyArray<{
            readonly id: string;
        }>;
        readonly isAdmin: boolean;
    } | null;
    readonly currentStream: {
        readonly id: string;
    } | null;
    readonly categories: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly subcategories: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly subcategories: ReadonlyArray<{
                readonly id: string;
                readonly name: string;
                readonly subcategories: ReadonlyArray<{
                    readonly id: string;
                    readonly name: string;
                    readonly subcategories: ReadonlyArray<{
                        readonly id: string;
                        readonly name: string;
                        readonly songs: ReadonlyArray<{
                            readonly id: string;
                            readonly name: string;
                            readonly sheetMusicUrl: string | null;
                        }>;
                    }>;
                    readonly songs: ReadonlyArray<{
                        readonly id: string;
                        readonly name: string;
                        readonly sheetMusicUrl: string | null;
                    }>;
                }>;
                readonly songs: ReadonlyArray<{
                    readonly id: string;
                    readonly name: string;
                    readonly sheetMusicUrl: string | null;
                }>;
            }>;
            readonly songs: ReadonlyArray<{
                readonly id: string;
                readonly name: string;
                readonly sheetMusicUrl: string | null;
            }>;
        }>;
        readonly songs: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly sheetMusicUrl: string | null;
        }>;
    }>;
};
export type SongListPageQuery = {
    readonly response: SongListPageQueryResponse;
    readonly variables: SongListPageQueryVariables;
};



/*
query SongListPageQuery {
  me {
    availableTokens {
      id
    }
    isAdmin
    id
  }
  currentStream {
    id
  }
  categories {
    id
    name
    subcategories {
      id
      name
      subcategories {
        id
        name
        subcategories {
          id
          name
          subcategories {
            id
            name
            songs {
              id
              name
              sheetMusicUrl
            }
          }
          songs {
            id
            name
            sheetMusicUrl
          }
        }
        songs {
          id
          name
          sheetMusicUrl
        }
      }
      songs {
        id
        name
        sheetMusicUrl
      }
    }
    songs {
      id
      name
      sheetMusicUrl
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "RequestToken",
  "kind": "LinkedField",
  "name": "availableTokens",
  "plural": true,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Stream",
  "kind": "LinkedField",
  "name": "currentStream",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "SongListEntry",
  "kind": "LinkedField",
  "name": "songs",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sheetMusicUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "SongListCategory",
  "kind": "LinkedField",
  "name": "categories",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SongListCategory",
      "kind": "LinkedField",
      "name": "subcategories",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SongListCategory",
          "kind": "LinkedField",
          "name": "subcategories",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "SongListCategory",
              "kind": "LinkedField",
              "name": "subcategories",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SongListCategory",
                  "kind": "LinkedField",
                  "name": "subcategories",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            (v6/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SongListPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      (v7/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SongListPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      (v7/*: any*/)
    ]
  },
  "params": {
    "cacheID": "c31fc4d8bd3100f4cf01bc71d4355d5a",
    "id": null,
    "metadata": {},
    "name": "SongListPageQuery",
    "operationKind": "query",
    "text": "query SongListPageQuery {\n  me {\n    availableTokens {\n      id\n    }\n    isAdmin\n    id\n  }\n  currentStream {\n    id\n  }\n  categories {\n    id\n    name\n    subcategories {\n      id\n      name\n      subcategories {\n        id\n        name\n        subcategories {\n          id\n          name\n          subcategories {\n            id\n            name\n            songs {\n              id\n              name\n              sheetMusicUrl\n            }\n          }\n          songs {\n            id\n            name\n            sheetMusicUrl\n          }\n        }\n        songs {\n          id\n          name\n          sheetMusicUrl\n        }\n      }\n      songs {\n        id\n        name\n        sheetMusicUrl\n      }\n    }\n    songs {\n      id\n      name\n      sheetMusicUrl\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd6f3d540a2c5919d3f8287de121f56f2';
export default node;
