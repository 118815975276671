import React, { ReactElement } from 'react';

const Table: React.FC<{}> = ({ children }) => {
  return <div className="flex flex-col">
    {/* <div className="-my-2 sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block sm:px-6 lg:px-8"> */}
        <div className="shadow border-b border-gray-200 sm:rounded-lg overflow-x-auto">
          <table className="divide-y divide-gray-200 w-full table-auto">
            {children}
          </table>
        {/* </div>
      </div> */}
    </div>
  </div>;
}

export default Table;