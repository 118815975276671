import React, { useCallback, useRef, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import { UploadMusicPageAuthQuery } from './__generated__/UploadMusicPageAuthQuery.graphql';
import { Navigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { UploadMusicPageUrlMutation } from './__generated__/UploadMusicPageUrlMutation.graphql';
import { UploadMusicPageConfirmUploadMutation } from './__generated__/UploadMusicPageConfirmUploadMutation.graphql';

export type UploadFile = {
  file: File;
  progress: number;
  url?: string;
  name?: string;
  xhr?: XMLHttpRequest;
  originalName: string;
};

function UploadMusicPage() {
  let data = useLazyLoadQuery<UploadMusicPageAuthQuery>(
    graphql`
      query UploadMusicPageAuthQuery {
        me {
          isAdmin
          availableTokens {
            id
          }
        }
        currentStream {
          id
        }
      }
    `,
    {}
  );

  let [getUploadUrls, getUploadUrlsInFlight] = useMutation<UploadMusicPageUrlMutation>(graphql`
    mutation UploadMusicPageUrlMutation($input: CreateUploadUrlsInput!) {
      createUploadUrls(input: $input) {
        urls {
          url
          name
          sheetMusicId
        }
      }
    }
  `);

  let [confirmUpload, confirmUploadInFlight] = useMutation<UploadMusicPageConfirmUploadMutation>(graphql`
    mutation UploadMusicPageConfirmUploadMutation($input: ConfirmUploadInput!) {
      confirmUpload(input: $input) {
        success
      }
    }
  `);

  const acceptedFiles = useRef([] as UploadFile[]);
  const [stateBump, setStateBump] = useState(0);

  const onDrop = useCallback((files: File[]) => {
    const mappedFiles: UploadFile[] = files.map(f => ({
      file: f,
      progress: 0,
      originalName: f.name,
    }));

    getUploadUrls({
      variables: {
        input: {
          names: files.map(f => f.name),
        }
      },
      onCompleted(response, errors) {
        if (!errors && response.createUploadUrls) {
          response.createUploadUrls.urls.forEach((url, i) => {
            mappedFiles[i].url = url.url;
            mappedFiles[i].name = url.name;

            const xhr = new XMLHttpRequest();
            mappedFiles[i].xhr = xhr;

            xhr.open('PUT', url.url);
            var blob = new Blob([mappedFiles[i].file], {type: mappedFiles[i].file.type});
            xhr.send(mappedFiles[i].file);
            xhr.onload = (e) => {
              confirmUpload({
                variables: {
                  input: {
                    sheetMusicId: url.sheetMusicId
                  }
                }
              })
            };
          });

          setStateBump(s => s + 1);
        }
      }
    })

    acceptedFiles.current.push(...mappedFiles);

    setStateBump(stateBump + 1);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({onDrop, accept: 'image/*, application/pdf'})

  if (!data.me || !data.me.isAdmin) {
    return <Navigate to="/"/>;
  }

  const className = 'p-5 bg-gray-200 border-2 border-gray-500 rounded';

  return <div>
    <header className="py-4 text-white">
      <div className="max-w-7xl ">
        <h1 className="text-3xl leading-9 font-bold text-white">
          Upload Music
        </h1>
      </div>
    </header>
    <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mt-5">
      <div {...getRootProps()} className={className}>
        <input {...getInputProps()} />
        { acceptedFiles.current.length > 0 && <>
          <ul>
            { acceptedFiles.current.map((f) => {
              return <li>
                {f.originalName} {f.url}
              </li>
            })}
          </ul>
        </>}
        { acceptedFiles.current.length === 0 && <>
          <div className='text-center'>
            Drag some images or PDFs here.
          </div>
        </>}
      </div>
    </div>
  </div>;
}

export default UploadMusicPage;