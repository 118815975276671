/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type Vote = "DOWN" | "NONE" | "UP" | "%future added value";
export type QueuePageListQueryVariables = {};
export type QueuePageListQueryResponse = {
    readonly me: {
        readonly availableTokens: ReadonlyArray<{
            readonly id: string;
        }>;
    } | null;
    readonly combokensEnabled: boolean;
    readonly votingEnabled: boolean;
    readonly currentStream: {
        readonly id: string;
        readonly status: string;
        readonly requests: ReadonlyArray<{
            readonly id: string;
            readonly value: number;
            readonly requestedBy: string;
            readonly status: string;
            readonly song: {
                readonly name: string;
                readonly filepath: string | null;
            };
            readonly createdAt: string;
            readonly canCombo: boolean;
            readonly timeBonus: number;
            readonly waitingTime: string;
            readonly formattedValue: string;
            readonly formattedValueForSwoop: string;
            readonly myVoteType: Vote;
            readonly vote: number;
        }>;
    } | null;
};
export type QueuePageListQuery = {
    readonly response: QueuePageListQueryResponse;
    readonly variables: QueuePageListQueryVariables;
};



/*
query QueuePageListQuery {
  me {
    availableTokens {
      id
    }
    id
  }
  combokensEnabled: featureEnabled(slug: "comboken")
  votingEnabled: featureEnabled(slug: "voting")
  currentStream {
    id
    status
    requests {
      id
      value
      requestedBy
      status
      song {
        name
        filepath
        id
      }
      createdAt
      canCombo
      timeBonus
      waitingTime
      formattedValue
      formattedValueForSwoop
      myVoteType
      vote
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "RequestToken",
  "kind": "LinkedField",
  "name": "availableTokens",
  "plural": true,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v2 = {
  "alias": "combokensEnabled",
  "args": [
    {
      "kind": "Literal",
      "name": "slug",
      "value": "comboken"
    }
  ],
  "kind": "ScalarField",
  "name": "featureEnabled",
  "storageKey": "featureEnabled(slug:\"comboken\")"
},
v3 = {
  "alias": "votingEnabled",
  "args": [
    {
      "kind": "Literal",
      "name": "slug",
      "value": "voting"
    }
  ],
  "kind": "ScalarField",
  "name": "featureEnabled",
  "storageKey": "featureEnabled(slug:\"voting\")"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requestedBy",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filepath",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canCombo",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeBonus",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "waitingTime",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattedValue",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattedValueForSwoop",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "myVoteType",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vote",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueuePageListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Stream",
        "kind": "LinkedField",
        "name": "currentStream",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SongRequest",
            "kind": "LinkedField",
            "name": "requests",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SongListEntry",
                "kind": "LinkedField",
                "name": "song",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueuePageListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Stream",
        "kind": "LinkedField",
        "name": "currentStream",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SongRequest",
            "kind": "LinkedField",
            "name": "requests",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SongListEntry",
                "kind": "LinkedField",
                "name": "song",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9fa41cab95702c23fe0bd9ed7c7761b9",
    "id": null,
    "metadata": {},
    "name": "QueuePageListQuery",
    "operationKind": "query",
    "text": "query QueuePageListQuery {\n  me {\n    availableTokens {\n      id\n    }\n    id\n  }\n  combokensEnabled: featureEnabled(slug: \"comboken\")\n  votingEnabled: featureEnabled(slug: \"voting\")\n  currentStream {\n    id\n    status\n    requests {\n      id\n      value\n      requestedBy\n      status\n      song {\n        name\n        filepath\n        id\n      }\n      createdAt\n      canCombo\n      timeBonus\n      waitingTime\n      formattedValue\n      formattedValueForSwoop\n      myVoteType\n      vote\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '65a4ebada10c6c2af1f010999cae4044';
export default node;
