import React from "react";

function ArrowDown({fill}: {fill?: string}) {
  if (!fill) {
    fill = '';
  }

  return <svg className="w-8 h-6 m-auto" fill="none" stroke="currentColor" viewBox="0 0 24 18" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" stroke="#161e2e" strokeLinejoin="round" fill={fill} strokeWidth="2" d="M19 7l-7 9-7-9 14-0"></path></svg>
}

export default ArrowDown;