import React, { Suspense, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay/hooks';
import graphql from 'babel-plugin-relay/macro';
import Button from './components/Button';
import { HeaderAuthInfoQuery } from './__generated__/HeaderAuthInfoQuery.graphql';
import { Transition } from '@headlessui/react';
import HeaderLink from './components/HeaderLink';
import { HeaderInfoQuery } from './__generated__/HeaderInfoQuery.graphql';
import { HeaderMobileAuthInfoQuery } from './__generated__/HeaderMobileAuthInfoQuery.graphql';
import clsx from 'clsx';

function MobileAuthSection() {
  const data = useLazyLoadQuery<HeaderMobileAuthInfoQuery>(
    graphql`
      query HeaderMobileAuthInfoQuery {
        me {
          id
          imageUrl
          isAdmin
        }
        charityOnly: featureEnabled(slug: "charity_only")
      }
    `,
    {},
  )

  return <div>
    { data.me && <a href="/api/logout" className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">Log Out</a>}
    { !data.me && <a href="/api/auth/twitch" className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">Log In</a>}
  </div>;
}

function AuthSection() {
  const data = useLazyLoadQuery<HeaderAuthInfoQuery>(
    graphql`
      query HeaderAuthInfoQuery {
        me {
          id
          imageUrl
          isAdmin
        }
        charityOnly: featureEnabled(slug: "charity_only")
      }
    `,
    {},
  )

  const [isOpen, setIsOpen] = useState(false);

  return <div className="ml-3 relative">
    <div>
      {data.me && (
        <button onClick={() => setIsOpen(!isOpen)} className="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid" id="user-menu" aria-label="User menu" aria-haspopup="true">
          <img className="h-8 w-8 rounded-full" src={data.me.imageUrl!} alt="" />
        </button>
      )}
      {!data.me && <Button label="Log In" size="xs" href="/api/auth/twitch" />}
    </div>
    <Transition
      show={isOpen}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95">
      <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
        <div className="py-1 rounded-md bg-white shadow-xs">
          <a href="/api/logout" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Log Out</a>
        </div>
      </div>
    </Transition>
  </div>;
}

function AuthedLinks() {
  const data = useLazyLoadQuery<HeaderInfoQuery>(
    graphql`
      query HeaderInfoQuery {
        me {
          id
          imageUrl
          isAdmin
        }
        charityOnly: featureEnabled(slug: "charity_only")
        charityLink
      }
    `,
    {},
  );

  let tipLink = <a rel='noopener noreferrer' target="_blank" href="https://streamlabs.com/88bitmusic/tip" className="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
    Tip
  </a>;

  if (data.charityOnly) {
    tipLink = <a rel='noopener noreferrer' target="_blank" href={data.charityLink} className="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">
      Donate
    </a>;
  }

  if (!data.me?.isAdmin) {
    return <>
      {tipLink}
    </>;
  }

  return <>
    {tipLink}
    <HeaderLink to="/stream_view">Stream View</HeaderLink>
    <HeaderLink to="/management">Management</HeaderLink>
  </>;
}

function Header() {
  const [mobileOpen, setMobileOpen] = useState(false);

  return <div className="bg-gray-800 pb-32">
    <nav className="bg-gray-800" x-data="{ mobileMenu: false }">
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="border-b border-gray-700">
          <div className="flex items-center justify-between h-16 px-4 sm:px-0">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img className="h-16 w-16" src="/logo.png" alt="Workflow logo" />
              </div>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  <HeaderLink to="/">Queue</HeaderLink>
                  <HeaderLink to="/song_list">Song List</HeaderLink>
                  <Suspense fallback={<></>}>
                    <AuthedLinks/>
                  </Suspense>
                  {/* <% @tabs.each_with_index do |tab, i| %>
                        <% if tab[:id] == @current_tab %>
                          <a href="<%= tab[:url] %>" className="<%= i == 0 ? '' : 'ml-4' %> px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700"><%= tab[:label] %></a>
                        <% else %>
                          <a href="<%= tab[:url] %>" className="<%= i == 0 ? '' : 'ml-4' %> px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"><%= tab[:label] %></a>
                        <% end %>
                      <% end %> */}
                </div>
              </div>
            </div>
            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
                <Suspense fallback={<></>}>
                  <AuthSection/>
                </Suspense>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white" onClick={() => { setMobileOpen(!mobileOpen) }}>
                <svg className="block h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                <svg className="hidden h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={clsx({"hidden": !mobileOpen}, "border-b border-gray-700 md:hidden")}>
        <div className="px-2 py-3 space-y-1 sm:px-3">
          <HeaderLink to="/" mobile={true}>Queue</HeaderLink>
          <HeaderLink to="/song_list" mobile={true}>Song List</HeaderLink>
          <Suspense fallback={<></>}>
            <MobileAuthSection/>
          </Suspense>
        </div>
      </div>
    </nav>
  </div>;
}

export default Header;