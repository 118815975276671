
import React, { Component, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import { useParams, Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';
import { RequestPageQuery } from './__generated__/RequestPageQuery.graphql';
import { Listbox } from '@headlessui/react';
import Button from '../components/Button';
import { commitMutation } from 'react-relay';
import environment from '../utils/environment';
import { RequestPageRequestMutation } from './__generated__/RequestPageRequestMutation.graphql';
import { useDropzone } from 'react-dropzone';
import { UploadFile } from './management/UploadMusicPage';
import { RequestPageUrlMutation } from './__generated__/RequestPageUrlMutation.graphql';

function RequestPage() {
  const [stateBump, setStateBump] = useState(0);
  const [sheetMusicId, setSheetMusicId] = useState<string | null>(null);
  const [sheetMusicDropped, setSheetMusicDropped] = useState(false);

  const { sid } = useParams<{sid: string}>();
  const data = useLazyLoadQuery<RequestPageQuery>(graphql`
    query RequestPageQuery($id: ID!) {
      me {
        availableTokens {
          id
          formattedValue
          unformattedValue
          kind
        }
      }
      currentStream {
        status
      }
      song(id: $id) {
        name
        requiresUpload
        currentCost
        currentCostFormatted
      }
      charityOnly: featureEnabled(slug: "charity_only")
      uploadEnabled: featureEnabled(slug: "cyos_uploads")
    }
  `, {id: sid});

  let navigate = useNavigate();
  let [note, setNote] = useState<string | null>(null);
  let [submitRequest, requestInFlight] = useMutation<RequestPageRequestMutation>(
    graphql`
      mutation RequestPageRequestMutation($input: MakeRequestInput!) {
        makeRequest(input: $input) {
          songRequest {
            id
          }
        }
      }
    `
  );

  let [getUploadUrls, getUploadUrlsInFlight] = useMutation<RequestPageUrlMutation>(graphql`
    mutation RequestPageUrlMutation($input: CreateUploadUrlsInput!) {
      createUploadUrls(input: $input) {
        urls {
          url
          name
          sheetMusicId
        }
      }
    }
  `);

  const selectTokenRef = useRef<HTMLSelectElement>(null);

  const acceptedFiles = useRef([] as UploadFile[]);

  const onDrop = useCallback((files: File[]) => {
    const mappedFiles: UploadFile[] = files.map(f => ({
      file: f,
      progress: 0,
      originalName: f.name,
    }));
    setSheetMusicDropped(true);

    getUploadUrls({
      variables: {
        input: {
          names: files.map(f => f.name),
        }
      },
      onCompleted(response, errors) {
        if (!errors && response.createUploadUrls) {
          response.createUploadUrls.urls.forEach((url, i) => {
            mappedFiles[i].url = url.url;
            mappedFiles[i].name = url.name;

            const xhr = new XMLHttpRequest();
            mappedFiles[i].xhr = xhr;

            xhr.open('PUT', url.url);
            var blob = new Blob([mappedFiles[i].file], {type: mappedFiles[i].file.type});
            xhr.send(mappedFiles[i].file);
            xhr.onload = (e) => {
              setSheetMusicId(url.sheetMusicId);
            };
          });

          setStateBump(s => s + 1);
        }
      }
    })

    acceptedFiles.current.push(...mappedFiles);

    setStateBump(stateBump + 1);
  }, []);

  useLayoutEffect(() => {
    setStateBump(v => v + 1);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({onDrop, accept: 'application/pdf', maxFiles: 1})

  if (!data.me) {
    window.location.pathname = '/';
  }

  if (!data.song) {
    return <>
      <header className="py-4 text-white">
        <div className="max-w-7xl ">
          <h1 className="text-3xl leading-9 font-bold text-white">
            Request
          </h1>
          You have {data.me?.availableTokens?.length} request {data.me?.availableTokens?.length == 1 ? 'token' : 'tokens'}.
        </div>
      </header>
      <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
        That's not a song...
      </div>
    </>;
  }

  if (data.me?.availableTokens.length == 0) {
    return <>
      <header className="py-4 text-white">
        <div className="max-w-7xl ">
          <h1 className="text-3xl leading-9 font-bold text-white">
            Request
          </h1>
          You have {data.me?.availableTokens?.length} request 0 request tokens, and you thought you'd try this?
        </div>
      </header>
      <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
        Sadly you do not have any request tokens.
      </div>
    </>;
  }

  const submit = () => {
    let tokenId = "";

    if (selectTokenRef.current) {
      tokenId = data.me?.availableTokens[parseInt(selectTokenRef.current.value)]?.id || ""
    }

    submitRequest({
      variables: {
        input: {
          songId: sid,
          requestTokenId: tokenId,
          note: note,
          sheetMusicId: sheetMusicId,
        },
      },
      onCompleted(response, errors) {
        if (errors) {
          alert(errors.map(e => e.message).join("\n"));
        } else {
          navigate('/');
        }
      }
    });
  };

  let at = data.me?.availableTokens || [];

  if (data.currentStream?.status != 'started') {
    return <>
      <header className="py-4 text-white">
        <div className="max-w-7xl ">
          <h1 className="text-3xl leading-9 font-bold text-white">
            Request
          </h1>
          You have {data.me?.availableTokens?.length} request {data.me?.availableTokens?.length == 1 ? 'token' : 'tokens'}.
        </div>
      </header>
      <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
        Requests are closed currently.
      </div>
    </>;
  }

  const className = 'p-5 bg-gray-100 border-2 border-gray-300 rounded';

  return <>
    <header className="py-4 text-white">
      <div className="max-w-7xl ">
        <h1 className="text-3xl leading-9 font-bold text-white">
          Request
        </h1>
        You have {data.me?.availableTokens?.length} request {data.me?.availableTokens?.length == 1 ? 'token' : 'tokens'}.
      </div>
    </header>
    <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
      <div>
        <label htmlFor="songName" className="block text-sm leading-5 font-medium text-gray-700">Song</label>
        <div id="songName">
          {data.song.name}
        </div>
      </div>
      {
        data.song.requiresUpload && data.uploadEnabled && <div className="mt-5">
          <label className="block text-sm leading-5 font-medium text-gray-700">Upload Music</label>
          <div {...getRootProps()} className={className}>
            <input {...getInputProps()} />
            {acceptedFiles.current.length > 0 && <>
              <ul>
                {acceptedFiles.current.map((f) => {
                  return <li>
                    {f.originalName}
                  </li>
                })}
              </ul>
            </>}
            {acceptedFiles.current.length === 0 && <>
              <div className='text-center'>
                Drag and drop PDF here. (You can also click!)
              </div>
            </>}
          </div>
        </div>
      }
      <div className="mt-5">
        <label htmlFor="token" className="block text-sm leading-5 font-medium text-gray-700">Token</label>
        {
          data.charityOnly && <div className="text-sm text-red-700">The request system is in Charity Mode. Only tokens labeled "Charity" will be accepted.</div>
        }
        {/* onChange={(e) => { setSelectedToken(at[parseInt(e.target.value)]) }} */}
        <select onChange={() => setStateBump(v => v + 1)} ref={selectTokenRef} id="token" className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5">
          {(data.me?.availableTokens || []).map((t, i) => {
            const disabled = (data.charityOnly && t.kind != 'CHARITY') || (data.song?.currentCost && (BigInt(data.song?.currentCost) > BigInt(t.unformattedValue)));
            return <option key={i} value={i} disabled={!!disabled}>{t.formattedValue}</option>
          })}
        </select>
        {data.song?.currentCost && <div>
          This song currently costs {data.song?.currentCostFormatted}
        </div>}
      </div>
      <div className="mt-5">
          <label htmlFor="note" className="block text-sm font-medium leading-5 text-gray-700">
            Note
          </label>
          <div className="mt-1 rounded-md shadow-sm">
            <input onChange={(e) => { setNote(e.target.value) }} id="note" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
          </div>
        </div>
      <div className="mt-5">
        { data.currentStream?.status != 'started' && <div className="mb-2">
            Requests are closed currently.
          </div>
        }
        <Button label={"Request"} onClick={submit} disabled={data.currentStream?.status != 'started' || requestInFlight || (sheetMusicDropped && !sheetMusicId) || !selectTokenRef.current?.value}/>
      </div>
    </div>
  </>;
}

export default RequestPage;