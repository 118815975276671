/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ConfirmUploadInput = {
    sheetMusicId: string;
    clientMutationId?: string | null;
};
export type UploadMusicPageConfirmUploadMutationVariables = {
    input: ConfirmUploadInput;
};
export type UploadMusicPageConfirmUploadMutationResponse = {
    readonly confirmUpload: {
        readonly success: boolean;
    } | null;
};
export type UploadMusicPageConfirmUploadMutation = {
    readonly response: UploadMusicPageConfirmUploadMutationResponse;
    readonly variables: UploadMusicPageConfirmUploadMutationVariables;
};



/*
mutation UploadMusicPageConfirmUploadMutation(
  $input: ConfirmUploadInput!
) {
  confirmUpload(input: $input) {
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ConfirmUploadPayload",
    "kind": "LinkedField",
    "name": "confirmUpload",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadMusicPageConfirmUploadMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UploadMusicPageConfirmUploadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "55dbfec379e535e7f2c8b9f5d34c012d",
    "id": null,
    "metadata": {},
    "name": "UploadMusicPageConfirmUploadMutation",
    "operationKind": "mutation",
    "text": "mutation UploadMusicPageConfirmUploadMutation(\n  $input: ConfirmUploadInput!\n) {\n  confirmUpload(input: $input) {\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = '71a0b6ea96b01c8233425d36e575350c';
export default node;
