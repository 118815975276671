/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SheetMusicsPageSearchQueryVariables = {
    input?: string | null;
};
export type SheetMusicsPageSearchQueryResponse = {
    readonly sheetMusicSearch: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly url: string;
        readonly createdAt: string;
        readonly addedByUser: {
            readonly username: string | null;
        } | null;
        readonly songCount: number | null;
    }>;
};
export type SheetMusicsPageSearchQuery = {
    readonly response: SheetMusicsPageSearchQueryResponse;
    readonly variables: SheetMusicsPageSearchQueryVariables;
};



/*
query SheetMusicsPageSearchQuery(
  $input: String
) {
  sheetMusicSearch(input: $input) {
    id
    name
    url
    createdAt
    addedByUser {
      username
      id
    }
    songCount
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "songCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SheetMusicsPageSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SheetMusic",
        "kind": "LinkedField",
        "name": "sheetMusicSearch",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "addedByUser",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SheetMusicsPageSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SheetMusic",
        "kind": "LinkedField",
        "name": "sheetMusicSearch",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "addedByUser",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c34d0aaa2ab6f329d3e7e34fe68a015",
    "id": null,
    "metadata": {},
    "name": "SheetMusicsPageSearchQuery",
    "operationKind": "query",
    "text": "query SheetMusicsPageSearchQuery(\n  $input: String\n) {\n  sheetMusicSearch(input: $input) {\n    id\n    name\n    url\n    createdAt\n    addedByUser {\n      username\n      id\n    }\n    songCount\n  }\n}\n"
  }
};
})();
(node as any).hash = 'df217a09cd0e56d370abdfcb9eafa763';
export default node;
