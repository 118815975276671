/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type StreamViewPageAuthQueryVariables = {};
export type StreamViewPageAuthQueryResponse = {
    readonly me: {
        readonly isAdmin: boolean;
        readonly availableTokens: ReadonlyArray<{
            readonly id: string;
        }>;
    } | null;
    readonly currentStream: {
        readonly id: string;
    } | null;
};
export type StreamViewPageAuthQuery = {
    readonly response: StreamViewPageAuthQueryResponse;
    readonly variables: StreamViewPageAuthQueryVariables;
};



/*
query StreamViewPageAuthQuery {
  me {
    isAdmin
    availableTokens {
      id
    }
    id
  }
  currentStream {
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "RequestToken",
  "kind": "LinkedField",
  "name": "availableTokens",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Stream",
  "kind": "LinkedField",
  "name": "currentStream",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StreamViewPageAuthQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StreamViewPageAuthQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "a190b35eff7cb8188493582b87f0ddd2",
    "id": null,
    "metadata": {},
    "name": "StreamViewPageAuthQuery",
    "operationKind": "query",
    "text": "query StreamViewPageAuthQuery {\n  me {\n    isAdmin\n    availableTokens {\n      id\n    }\n    id\n  }\n  currentStream {\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '6eb0c5154307469464b146026456cae6';
export default node;
