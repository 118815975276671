/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeleteSongsInput = {
    ids: Array<string>;
    clientMutationId?: string | null;
};
export type SongListManagementPageDeleteSongsMutationVariables = {
    input: DeleteSongsInput;
};
export type SongListManagementPageDeleteSongsMutationResponse = {
    readonly deleteSongs: {
        readonly deleted: number | null;
    } | null;
};
export type SongListManagementPageDeleteSongsMutation = {
    readonly response: SongListManagementPageDeleteSongsMutationResponse;
    readonly variables: SongListManagementPageDeleteSongsMutationVariables;
};



/*
mutation SongListManagementPageDeleteSongsMutation(
  $input: DeleteSongsInput!
) {
  deleteSongs(input: $input) {
    deleted
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteSongsPayload",
    "kind": "LinkedField",
    "name": "deleteSongs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SongListManagementPageDeleteSongsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SongListManagementPageDeleteSongsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "324c321e3b38d562b444bfd2e724ca41",
    "id": null,
    "metadata": {},
    "name": "SongListManagementPageDeleteSongsMutation",
    "operationKind": "mutation",
    "text": "mutation SongListManagementPageDeleteSongsMutation(\n  $input: DeleteSongsInput!\n) {\n  deleteSongs(input: $input) {\n    deleted\n  }\n}\n"
  }
};
})();
(node as any).hash = '3ba3cfc3418ed3e0ba10c6c0be9985ff';
export default node;
