import React from 'react';
import clsx from 'clsx';

type ButtonProps = {
  label: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  color?: 'indigo' | 'white' | 'red'
  href?: string;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
};

function Button({onClick, label, href, size, disabled, color, className}: ButtonProps) {
  if (!size) {
    size = 'md';
  }

  if (!color) {
    color = 'indigo';
  }

  const isIndigo = color === 'indigo';
  const isWhite = color === 'white';
  const isRed = color === 'red';

  const classes = clsx(
    className || "",
    "font-medium inline-flex items-center border rounded-md text-white focus:outline-none transition ease-in-out duration-150",
    {
      "px-2.5": size === 'xs',
      "py-1.5": size === 'xs',
      "text-xs": size === 'xs',
      "leading-4": size === 'xs' || size === 'sm',
      "px-3": size === 'sm',
      "px-4": size === 'md' || size === 'lg',
      "py-2": size === 'md' || size === 'lg' || size === 'sm',
      "text-sm": size === 'md' || size === 'sm',
      "leading-5": size === 'md',
      "text-base": size === 'lg' || size === 'xl',
      "leading-6": size === 'lg' || size === 'xl',
      "px-6": size === 'xl',
      "py-4": size === 'xl',
      "bg-gray-400": disabled,
      "bg-indigo-600": !disabled && isIndigo,
      "hover:bg-indigo-500": !disabled && isIndigo,
      "focus:border-indigo-700": isIndigo,
      "focus:shadow-outline-indigo": isIndigo,
      "active:bg-indigo-700": isIndigo,
      "text-white": isIndigo || isRed,
      "border-transparent": isIndigo || isRed,

      "bg-white": !disabled && isWhite,
      "hover:text-gray-500": !disabled && isWhite,
      "text-gray-700": isWhite,
      "focus:border-blue-300": isWhite,
      "focus:shadow-outline-blue": isWhite,
      "active:text-gray-800": isWhite,
      "active:bg-gray-50": isWhite,
      "border-gray-300": isWhite,

      "bg-red-600": !disabled && isRed,
      "hover:bg-red-500": !disabled && isRed,
      "focus:border-red-700": isRed,
      "focus:shadow-outline-red": isRed,
      "active:bg-red-700": isRed,
    }
  );

  if (href) {
    return <span className={clsx('inline-flex rounded-md shadow-sm')}>
      <a href={href} className={classes}>
        {label}
      </a>
    </span>;
  } else {
    return <span className={clsx('inline-flex rounded-md shadow-sm')}>
      <button type="button" className={classes} onClick={onClick} disabled={disabled}>
        {label}
      </button>
    </span>;
  }
};

export default Button;