/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ManagementPageQueryVariables = {};
export type ManagementPageQueryResponse = {
    readonly me: {
        readonly username: string | null;
        readonly isAdmin: boolean;
    } | null;
};
export type ManagementPageQuery = {
    readonly response: ManagementPageQueryResponse;
    readonly variables: ManagementPageQueryVariables;
};



/*
query ManagementPageQuery {
  me {
    username
    isAdmin
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ManagementPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ManagementPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "006c74aec402f7e2cbe0ce3e877e9831",
    "id": null,
    "metadata": {},
    "name": "ManagementPageQuery",
    "operationKind": "query",
    "text": "query ManagementPageQuery {\n  me {\n    username\n    isAdmin\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '0efbce81def717b23a772cb114611644';
export default node;
