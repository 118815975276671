/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FeatureTogglePageQueryVariables = {};
export type FeatureTogglePageQueryResponse = {
    readonly me: {
        readonly isAdmin: boolean;
    } | null;
    readonly featureToggles: ReadonlyArray<{
        readonly id: string;
        readonly label: string;
        readonly enabled: boolean;
    }>;
};
export type FeatureTogglePageQuery = {
    readonly response: FeatureTogglePageQueryResponse;
    readonly variables: FeatureTogglePageQueryVariables;
};



/*
query FeatureTogglePageQuery {
  me {
    isAdmin
    id
  }
  featureToggles {
    id
    label
    enabled
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "FeatureToggle",
  "kind": "LinkedField",
  "name": "featureToggles",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabled",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeatureTogglePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FeatureTogglePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "c110aae184f6391c35f153552cc2b570",
    "id": null,
    "metadata": {},
    "name": "FeatureTogglePageQuery",
    "operationKind": "query",
    "text": "query FeatureTogglePageQuery {\n  me {\n    isAdmin\n    id\n  }\n  featureToggles {\n    id\n    label\n    enabled\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bb72546b385eacd4ef7dd2ebe419fa86';
export default node;
