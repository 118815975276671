import React, { Component, useRef, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation, useRelayEnvironment } from 'react-relay/hooks';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';
import { Listbox } from '@headlessui/react';
import Button from '../../components/Button';
import { commitMutation } from 'react-relay';
import environment from '../../utils/environment';
import { DefaultDeserializer } from 'v8';
import { RequestTokenPageQuery } from './__generated__/RequestTokenPageQuery.graphql';
import { RequestTokenPageNewTokenMutation } from './__generated__/RequestTokenPageNewTokenMutation.graphql';

function RequestTokenPage() {
  const { sid } = useParams<{sid: string}>();
  const data = useLazyLoadQuery<RequestTokenPageQuery>(graphql`
    query RequestTokenPageQuery {
      me {
        isAdmin
      }
      users {
        edges {
          node {
            username
            availableTokens {
              value
              formattedValue
              createdAt
            }
          }
        }
      }
    }
  `, {id: sid});

  let [submitNewToken, newTokenInFlight] = useMutation<RequestTokenPageNewTokenMutation>(
    graphql`
      mutation RequestTokenPageNewTokenMutation($input: NewTokenInput!) {
        newToken(input: $input) {
          id
          user {
            availableTokens {
              value
              formattedValue
              createdAt
            }
          }
        }
      }
    `
  );

  const valueRef = useRef<HTMLInputElement>(null);
  const usernameRef = useRef<HTMLInputElement>(null);

  if (!data.me || !data.me.isAdmin) {
    window.location.pathname = '/';
  }

  const submit = () => {
    submitNewToken({
      variables: {
        input: {
          twitchUsername: usernameRef.current?.value || "",
          value: parseInt(valueRef.current?.value || "500"),
        },
      },
      onCompleted(response, errors) {
        if (response.newToken?.id) {
          if (usernameRef.current) {
            usernameRef.current.value = ""
          }
        } else {
          alert('The username was probably not actually a username or something. Also I was lazy so this is an alert box.');
        }
      }
    });
  };

  const users = data.users.edges || []

  return <>
    <header className="py-4 text-white">
      <div className="max-w-7xl ">
        <h1 className="text-3xl leading-9 font-bold text-white">
          Make Token
        </h1>
      </div>
    </header>
    <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
      <div className="mt-5">
        <label htmlFor="value" className="block text-sm font-medium leading-5 text-gray-700">
          Value
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <input defaultValue={500} ref={valueRef} id="value" type="number" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
        </div>
      </div>
      <div className="mt-5">
        <label htmlFor="username" className="block text-sm font-medium leading-5 text-gray-700">
          Username
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <input ref={usernameRef} id="username" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
        </div>
      </div>
      <div className="mt-5">
        <Button label={"Make"} onClick={submit} disabled={newTokenInFlight}/>
      </div>
    </div>
    <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mt-5">
      {
        users.map(e => {
          if (!e || !e.node) { return false; }
          const user = e.node;

          return <div className="mt-2">
            {user.username}

            { user.availableTokens.map(t => {
              return <div className="pl-5">
                <span className="font-mono">{moment(t.createdAt * 1000).format('MMMM Do YYYY, h:mm:ss a')}</span>
                <span className="pl-10 font-mono">{t.formattedValue}</span>
              </div>;
            })}
          </div>;
        })
      }
    </div>
  </>;
}

export default RequestTokenPage;