/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type QueuePageQueryVariables = {};
export type QueuePageQueryResponse = {
    readonly me: {
        readonly availableTokens: ReadonlyArray<{
            readonly id: string;
        }>;
    } | null;
};
export type QueuePageQuery = {
    readonly response: QueuePageQueryResponse;
    readonly variables: QueuePageQueryVariables;
};



/*
query QueuePageQuery {
  me {
    availableTokens {
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "RequestToken",
  "kind": "LinkedField",
  "name": "availableTokens",
  "plural": true,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueuePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueuePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "432f30e34a3eddd70f3cf09555e86893",
    "id": null,
    "metadata": {},
    "name": "QueuePageQuery",
    "operationKind": "query",
    "text": "query QueuePageQuery {\n  me {\n    availableTokens {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '6682278812f65b48021721ae910b0c19';
export default node;
