import React, { ReactElement } from 'react';

const TableHeader: React.FC<{}> = ({ children }) => {
  return <thead className="bg-gray-50">
    <tr>
      {children}
    </tr>
  </thead>;
}

export default TableHeader;