/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type HeaderAuthInfoQueryVariables = {};
export type HeaderAuthInfoQueryResponse = {
    readonly me: {
        readonly id: string;
        readonly imageUrl: string | null;
        readonly isAdmin: boolean;
    } | null;
    readonly charityOnly: boolean;
};
export type HeaderAuthInfoQuery = {
    readonly response: HeaderAuthInfoQueryResponse;
    readonly variables: HeaderAuthInfoQueryVariables;
};



/*
query HeaderAuthInfoQuery {
  me {
    id
    imageUrl
    isAdmin
  }
  charityOnly: featureEnabled(slug: "charity_only")
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "imageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isAdmin",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "charityOnly",
    "args": [
      {
        "kind": "Literal",
        "name": "slug",
        "value": "charity_only"
      }
    ],
    "kind": "ScalarField",
    "name": "featureEnabled",
    "storageKey": "featureEnabled(slug:\"charity_only\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HeaderAuthInfoQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HeaderAuthInfoQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "e90ee8302e7cfd13311e9567d756365b",
    "id": null,
    "metadata": {},
    "name": "HeaderAuthInfoQuery",
    "operationKind": "query",
    "text": "query HeaderAuthInfoQuery {\n  me {\n    id\n    imageUrl\n    isAdmin\n  }\n  charityOnly: featureEnabled(slug: \"charity_only\")\n}\n"
  }
};
})();
(node as any).hash = 'd43cda4ba19f3ff2580757427145aadb';
export default node;
