import React, { Suspense } from 'react';
import './App.css';
import Button from './components/Button';
import { RelayEnvironmentProvider, useLazyLoadQuery } from 'react-relay/hooks';
import graphql from 'babel-plugin-relay/macro';
import Header from './Header';
import environment from './utils/environment';

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import QueuePage from './pages/QueuePage';
import SongListPage from './pages/SongListPage';
import RequestPage from './pages/RequestPage';
import StreamViewPage from './pages/StreamViewPage';
import ComboPage from './pages/ComboPage';
import OverlayQueuePage from './pages/OverlayQueuePage';
import UploadMusicPage from './pages/management/UploadMusicPage';
import RequestTokenPage from './pages/management/RequestTokenPage';
import ManagementPage from './pages/management/ManagementPage';
import FeatureTogglePage from './pages/management/FeatureTogglePage';
import CategoryManagementPage from './pages/management/CategoryManagementPage';
import SheetMusicsPage from './pages/management/SheetMusicsPage';
import ConfigurableValuePage from './pages/management/ConfigurableValuePage';
import CommandsPage from './pages/management/CommandsPage';
import SongListManagementPage from './pages/management/SongListManagementPage';

const Layout: React.FC<{}> = ({children}) => {
  return <>
    <Header />
    <main className="-mt-32">
      <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">{children}</div>
    </main>
  </>;
}

function App() {
  // const data = useLazyLoadQuery<AppQuery>(graphql`
  //   query AppQuery {
  //     me {
  //       id
  //       isAdmin
  //     }
  //   }
  // `, {})

  return (
    <RelayEnvironmentProvider environment={environment}>
      <div>
        <Router>
              <Routes>
                <Route path="/overlay" element={
                  <OverlayQueuePage/>
                }/>
                <Route path="/requests/new/:sid" element={
                  <Layout>
                    <Suspense fallback={<></>}>
                      <RequestPage />
                    </Suspense>
                  </Layout>
                }/>
                <Route path="/combo/:rid" element={
                  <Layout>
                    <Suspense fallback={<></>}>
                      <ComboPage/>
                    </Suspense>
                  </Layout>
                }/>
                <Route path="/song_list" element={
                  <Layout>
                    <Suspense fallback={<></>}>
                      <SongListPage/>
                    </Suspense>
                  </Layout>
                }/>
                <Route path="/stream_view" element={
                  <Layout>
                    <Suspense fallback={<></>}>
                      <StreamViewPage/>
                    </Suspense>
                  </Layout>
                }/>
                <Route path="/management/tokens" element={
                  <Layout>
                    <Suspense fallback={<></>}>
                      <RequestTokenPage/>
                    </Suspense>
                  </Layout>
                }/>
                <Route path="/management/upload_music" element={
                  <Layout>
                    <Suspense fallback={<></>}>
                      <UploadMusicPage/>
                    </Suspense>
                  </Layout>
                }/>
                <Route path="/management/sheet_music" element={
                  <Layout>
                    <Suspense fallback={<></>}>
                      <SheetMusicsPage/>
                    </Suspense>
                  </Layout>
                }/>
                <Route path="/management/categories" element={
                  <Layout>
                    <Suspense fallback={<></>}>
                      <CategoryManagementPage/>
                    </Suspense>
                  </Layout>
                }/>
                <Route path="/management/feature_toggles" element={
                  <Layout>
                    <Suspense fallback={<></>}>
                      <FeatureTogglePage/>
                    </Suspense>
                  </Layout>
                }/>
                <Route path="/management/configurable_values" element={
                  <Layout>
                    <Suspense fallback={<></>}>
                      <ConfigurableValuePage/>
                    </Suspense>
                  </Layout>
                }/>
                <Route path="/management/commands" element={
                  <Layout>
                    <Suspense fallback={<></>}>
                      <CommandsPage/>
                    </Suspense>
                  </Layout>
                }/>
                <Route path="/management/song_list" element={
                  <Layout>
                    <Suspense fallback={<></>}>
                      <SongListManagementPage/>
                    </Suspense>
                  </Layout>
                }/>
                <Route path="/management" element={
                  <Layout>
                    <Suspense fallback={<></>}>
                      <ManagementPage/>
                    </Suspense>
                  </Layout>
                }/>
                <Route path="/" element={
                  <Layout>
                    <Suspense fallback={<></>}>
                      <QueuePage/>
                    </Suspense>
                  </Layout>
                }/>
              </Routes>
              {/* <%# <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6"> %>
              <%= yield %>
            <%# </div> %> */}
        </Router>
      </div>
    </RelayEnvironmentProvider>
  );
}

export default App;
