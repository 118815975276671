/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UploadMusicPageAuthQueryVariables = {};
export type UploadMusicPageAuthQueryResponse = {
    readonly me: {
        readonly isAdmin: boolean;
        readonly availableTokens: ReadonlyArray<{
            readonly id: string;
        }>;
    } | null;
    readonly currentStream: {
        readonly id: string;
    } | null;
};
export type UploadMusicPageAuthQuery = {
    readonly response: UploadMusicPageAuthQueryResponse;
    readonly variables: UploadMusicPageAuthQueryVariables;
};



/*
query UploadMusicPageAuthQuery {
  me {
    isAdmin
    availableTokens {
      id
    }
    id
  }
  currentStream {
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "RequestToken",
  "kind": "LinkedField",
  "name": "availableTokens",
  "plural": true,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Stream",
  "kind": "LinkedField",
  "name": "currentStream",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadMusicPageAuthQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UploadMusicPageAuthQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "b25e7f439d6090d28fab8ac012463c9a",
    "id": null,
    "metadata": {},
    "name": "UploadMusicPageAuthQuery",
    "operationKind": "query",
    "text": "query UploadMusicPageAuthQuery {\n  me {\n    isAdmin\n    availableTokens {\n      id\n    }\n    id\n  }\n  currentStream {\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd093bc5208837a3cdfe09b4e8a5f9c03';
export default node;
