/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CommandsPageQueryVariables = {};
export type CommandsPageQueryResponse = {
    readonly me: {
        readonly username: string | null;
        readonly isAdmin: boolean;
    } | null;
    readonly commandsDefinition: {
        readonly version: number;
        readonly definition: string;
    } | null;
};
export type CommandsPageQuery = {
    readonly response: CommandsPageQueryResponse;
    readonly variables: CommandsPageQueryVariables;
};



/*
query CommandsPageQuery {
  me {
    username
    isAdmin
    id
  }
  commandsDefinition {
    version
    definition
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "definition",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommandsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CommandsDefinition",
        "kind": "LinkedField",
        "name": "commandsDefinition",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CommandsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CommandsDefinition",
        "kind": "LinkedField",
        "name": "commandsDefinition",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bac3c38eb95019432cf02263391d690e",
    "id": null,
    "metadata": {},
    "name": "CommandsPageQuery",
    "operationKind": "query",
    "text": "query CommandsPageQuery {\n  me {\n    username\n    isAdmin\n    id\n  }\n  commandsDefinition {\n    version\n    definition\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '547823991a44e5656b1f6c3cb0817774';
export default node;
