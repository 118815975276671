import React, { useEffect } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation, useQueryLoader, usePreloadedQuery, PreloadedQuery, VariablesOf, LoadQueryOptions } from 'react-relay/hooks';
import { StreamViewPageQuery } from './__generated__/StreamViewPageQuery.graphql';
import { Link, Navigate} from 'react-router-dom';
import moment from 'moment';
import { StreamViewPageRefundRequestMutation } from './__generated__/StreamViewPageRefundRequestMutation.graphql';
import { StreamViewPageAuthQuery } from './__generated__/StreamViewPageAuthQuery.graphql';
import Button from '../components/Button';
import clsx from 'clsx';
import environment from '../utils/environment';
import { StreamViewPageStartRequestMutation } from './__generated__/StreamViewPageStartRequestMutation.graphql';
import { StreamViewPageFinishRequestMutation } from './__generated__/StreamViewPageFinishRequestMutation.graphql';
import { StreamViewPageStartStreamMutation } from './__generated__/StreamViewPageStartStreamMutation.graphql';
import { StreamViewPagePauseStreamMutation } from './__generated__/StreamViewPagePauseStreamMutation.graphql';
import { StreamViewPageUnpauseStreamMutation } from './__generated__/StreamViewPageUnpauseStreamMutation.graphql';
import { StreamViewPageFinishStreamMutation } from './__generated__/StreamViewPageFinishStreamMutation.graphql';
import Alert from '../components/Alert';

const query = graphql`
  query StreamViewPageQuery {
    me {
      isAdmin
      availableTokens {
        id
      }
    }
    charityOnly: featureEnabled(slug: "charity_only")
    currentStream {
      id
      status
      requests {
        id
        value
        formattedValue
        requestedBy
        firstRequester
        note
        status
        timeBonus
        song {
          name
          filepath
        }
        sheetMusicUrl
        createdAt
      }
    }
  }
`;

function StreamControls({requestsRemain, currentlyStreaming, status, loadQuery}: {requestsRemain: boolean, currentlyStreaming: boolean, status?: string, loadQuery: (variables: VariablesOf<StreamViewPageQuery>, options?: LoadQueryOptions) => void}) {
  let [startStream, startStreamInFlight] = useMutation<StreamViewPageStartStreamMutation>(
    graphql`
      mutation StreamViewPageStartStreamMutation {
        startStream(input: {}) {
          success
        }
      }
    `
  );

  let [pauseStream, pauseStreamInFlight] = useMutation<StreamViewPagePauseStreamMutation>(
    graphql`
      mutation StreamViewPagePauseStreamMutation {
        pauseStream(input: {}) {
          success
        }
      }
    `
  );

  let [unpauseStream, unpauseStreamInFlight] = useMutation<StreamViewPageUnpauseStreamMutation>(
    graphql`
      mutation StreamViewPageUnpauseStreamMutation {
        unpauseStream(input: {}) {
          success
        }
      }
    `
  );

  let [finishStream, finishStreamInFlight] = useMutation<StreamViewPageFinishStreamMutation>(
    graphql`
      mutation StreamViewPageFinishStreamMutation {
        finishStream(input: {}) {
          success
        }
      }
    `
  );

  return <>
    <div className="mb-3 bg-white text-lg rounded-lg shadow px-4 py-4">
      {
        currentlyStreaming && status === 'paused' &&
          <span>
            <Button label="Unpause Requests" onClick={() => {
              if (unpauseStreamInFlight) {
                return;
              }

              unpauseStream(
                {
                  variables: {},
                  onCompleted: () => { loadQuery({}, { fetchPolicy: "store-and-network" }) }
                }
              );
            }} />
          </span>
      }
      {
        currentlyStreaming && status === 'started' &&
          <span>
            <Button label="Pause Requests" onClick={() => {
              if (pauseStreamInFlight) {
                return;
              }

              pauseStream(
                {
                  variables: {},
                  onCompleted: () => { loadQuery({}, { fetchPolicy: "store-and-network" }) }
                }
              );
            }} />
          </span>
      }
      {
        currentlyStreaming &&
          <span className="float-right">
            <Button label="End Stream" disabled={requestsRemain} onClick={() => {
              if (finishStreamInFlight) {
                return;
              }

              finishStream(
                {
                  variables: {},
                  onCompleted: () => { loadQuery({}, { fetchPolicy: "store-and-network" }) }
                }
              );
            }} />
        </span>
      }
      {
        !currentlyStreaming &&
          <span className="float-right">
            <Button label="Start Stream" onClick={() => {
              if (startStreamInFlight) {
                return;
              }

              startStream(
                {
                  variables: {},
                  onCompleted: () => { loadQuery({}, { fetchPolicy: "store-and-network" }) }
                }
              );
            }} />
        </span>
      }
    </div>
  </>;
}

function StreamViewPageRequestList({queryReference, loadQuery}: {queryReference: PreloadedQuery<StreamViewPageQuery>, loadQuery: (variables: VariablesOf<StreamViewPageQuery>, options?: LoadQueryOptions) => void}) {
  const data = usePreloadedQuery<StreamViewPageQuery>(query, queryReference);

  let [refundRequest, refundRequestInFlight] = useMutation<StreamViewPageRefundRequestMutation>(
    graphql`
      mutation StreamViewPageRefundRequestMutation($input: RefundRequestInput!) {
        refundRequest(input: $input) {
          success
        }
      }
    `
  );

  let [startRequest, startRequestInFlight] = useMutation<StreamViewPageStartRequestMutation>(
    graphql`
      mutation StreamViewPageStartRequestMutation($input: StartRequestInput!) {
        startRequest(input: $input) {
          success
        }
      }
    `
  );

  let [finishRequest, finishRequestInFlight] = useMutation<StreamViewPageFinishRequestMutation>(
    graphql`
      mutation StreamViewPageFinishRequestMutation($input: FinishRequestInput!) {
        finishRequest(input: $input) {
          success
        }
      }
    `
  );

  if (!data.currentStream) {
    return <>
      <StreamControls requestsRemain={false} currentlyStreaming={false} loadQuery={loadQuery}/>
      <div className="bg-white text-lg rounded-lg shadow px-8 py-9 sm:px-6 text-center">
        You aren't streaming right now.
      </div>
    </>;
  }

  const anyStarted = !!data.currentStream.requests.find(r => r.status === 'started');

  if (data.currentStream.requests.length === 0) {
    return <>
      { data.charityOnly && <Alert text="Charity Mode Enabled"/>}
      <StreamControls requestsRemain={data.currentStream.requests.length > 0} currentlyStreaming={true} status={data.currentStream.status} loadQuery={loadQuery}/>
      <div className="bg-white text-lg rounded-lg shadow px-8 py-9 sm:px-6 text-center">
        You did it. You finished all the requests.
      </div>
    </>;
  }

  return <>
    { data.charityOnly && <Alert text="Charity Mode Enabled"/>}
    <StreamControls requestsRemain={data.currentStream.requests.length > 0} currentlyStreaming={true} status={data.currentStream.status} loadQuery={loadQuery}/>
    <div className="divide-y divide-gray-400 align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-lg">
      {
        data.currentStream.requests.map((r, i) => {
          return <div className={clsx({"bg-white": r.status === 'pending', "bg-green-200": r.status === 'started'})} key={i}>
            <div className="w-full px-3 py-2 sm:px-6 sm:py-4 whitespace-no-wrap text-md sm:text-md leading-5 text-gray-900">
              <div className="float-right">
                <div className="text-lg text-right">
                  {r.formattedValue} <span className="text-xs">+ ${(r.timeBonus / 100).toFixed(2)}</span>
                </div>
                <div className="text-sm text-gray-700">
                  {r.requestedBy}
                </div>
              </div>
              <div>
                <div className="text-lg">
                  {
                    r.song.filepath && !r.sheetMusicUrl &&
                      <a className="underline hover:text-gray-600" href={`file:///D:/Seafile/Sheet Music${r.song.filepath}`}>{r.song.name}</a>
                  }
                  {
                    r.sheetMusicUrl &&
                      <a className="underline text-blue-500 hover:text-blue-600" href='#' onClick={(e) => {
                        e.preventDefault();
                        let url = r.sheetMusicUrl;
                        if (!url) {
                          url = '';
                        }

                        window.open(url, 'TheSheetMusicWindow');
                      }}>{r.song.name}</a>
                  }
                </div>
                <div className="text-sm text-gray-700">
                  {moment(r.createdAt).fromNow()}
                </div>
              </div>
              { r.note && r.note !== "" && (
                <div className="mt-2 whitespace-normal">
                  <span className="font-bold">{r.firstRequester}</span> says "{r.note}"
                </div>
              )}
              <div className="mt-2">
                <span className="mr-1">
                  {
                    r.status === 'pending' &&
                      <Button label="Start" size="xs" disabled={anyStarted} onClick={() => {
                        if (startRequestInFlight) {
                          return;
                        }

                        startRequest(
                          {
                            variables: {input: {requestId: r.id} },
                            onCompleted: () => { loadQuery({}, {fetchPolicy: "store-and-network"}) }
                          }
                        );
                      }}/>
                  }
                  {
                    r.status === 'started' &&
                      <Button label="finish" size="xs" onClick={() => {
                        if (finishRequestInFlight) {
                          return;
                        }

                        finishRequest(
                          {
                            variables: {input: {requestId: r.id} },
                            onCompleted: () => { loadQuery({}, {fetchPolicy: "store-and-network"}) }
                          }
                        );
                      }}/>
                  }
                </span>
                <Button label="Refund" size="xs" color="white" onClick={() => {
                  if (refundRequestInFlight) {
                    return;
                  }

                  refundRequest(
                    {
                      variables: {input: {requestId: r.id} },
                      onCompleted: () => { loadQuery({}, {fetchPolicy: "store-and-network"}) }
                    }
                  );
                }}/>
              </div>
            </div>
          </div>
        })
      }
    </div>
  </>
}

function StreamViewPage() {
  const [
    queryReference,
    loadQuery,
    disposeQuery,
  ] = useQueryLoader<StreamViewPageQuery>(query);

  useEffect(() => {
    loadQuery({});

    const handle = setInterval(() => {
      loadQuery({}, {
        fetchPolicy: "store-and-network",
      });
    }, 5000);

    return () => {
      clearInterval(handle);
    }
  }, []);

  let data = useLazyLoadQuery<StreamViewPageAuthQuery>(
    graphql`
      query StreamViewPageAuthQuery {
        me {
          isAdmin
          availableTokens {
            id
          }
        }
        currentStream {
          id
        }
      }
    `,
    {}
  );

  if (!data.me || !data.me.isAdmin) {
    return <Navigate to="/"/>;
  }

  // if (!data.currentStream) {
  //   return <>
  //     <header className="py-4 text-white">
  //       <div className="max-w-7xl ">
  //         <h1 className="text-3xl leading-9 font-bold text-white">
  //           Stream View
  //         </h1>
  //         Hi Trevor!
  //       </div>
  //     </header>
  //     <div className="divide-y divide-gray-400 align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-lg">
  //       You aren't streaming right now.
  //     </div>
  //   </>;
  // }

  return <>
    <header className="py-4 text-white">
      <div className="max-w-7xl ">
        <h1 className="text-3xl leading-9 font-bold text-white">
          Stream View
        </h1>
        Hi Trevor!
      </div>
    </header>
    {
      queryReference != null && <StreamViewPageRequestList queryReference={queryReference} loadQuery={loadQuery}/>
    }
  </>;
}

export default StreamViewPage;