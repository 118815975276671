/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SongListPage2QueryVariables = {};
export type SongListPage2QueryResponse = {
    readonly me: {
        readonly availableTokens: ReadonlyArray<{
            readonly id: string;
        }>;
        readonly isAdmin: boolean;
    } | null;
    readonly newList: boolean;
};
export type SongListPage2Query = {
    readonly response: SongListPage2QueryResponse;
    readonly variables: SongListPage2QueryVariables;
};



/*
query SongListPage2Query {
  me {
    availableTokens {
      id
    }
    isAdmin
    id
  }
  newList: featureEnabled(slug: "new_list")
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "RequestToken",
  "kind": "LinkedField",
  "name": "availableTokens",
  "plural": true,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v3 = {
  "alias": "newList",
  "args": [
    {
      "kind": "Literal",
      "name": "slug",
      "value": "new_list"
    }
  ],
  "kind": "ScalarField",
  "name": "featureEnabled",
  "storageKey": "featureEnabled(slug:\"new_list\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SongListPage2Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SongListPage2Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "5164462c7699168f596abd92ff39ced7",
    "id": null,
    "metadata": {},
    "name": "SongListPage2Query",
    "operationKind": "query",
    "text": "query SongListPage2Query {\n  me {\n    availableTokens {\n      id\n    }\n    isAdmin\n    id\n  }\n  newList: featureEnabled(slug: \"new_list\")\n}\n"
  }
};
})();
(node as any).hash = '646e4e764d7248477d066672a1dc4fa0';
export default node;
