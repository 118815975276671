/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type StreamViewPageStartStreamMutationVariables = {};
export type StreamViewPageStartStreamMutationResponse = {
    readonly startStream: {
        readonly success: boolean;
    } | null;
};
export type StreamViewPageStartStreamMutation = {
    readonly response: StreamViewPageStartStreamMutationResponse;
    readonly variables: StreamViewPageStartStreamMutationVariables;
};



/*
mutation StreamViewPageStartStreamMutation {
  startStream(input: {}) {
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {}
      }
    ],
    "concreteType": "StartStreamPayload",
    "kind": "LinkedField",
    "name": "startStream",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": "startStream(input:{})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StreamViewPageStartStreamMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StreamViewPageStartStreamMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "bac215ffe68fa8b5bb5cbca00c3e2e27",
    "id": null,
    "metadata": {},
    "name": "StreamViewPageStartStreamMutation",
    "operationKind": "mutation",
    "text": "mutation StreamViewPageStartStreamMutation {\n  startStream(input: {}) {\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = '27b4ba3efc3b30cd259e106961058aaa';
export default node;
