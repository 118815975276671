import React, { useEffect } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { LoadQueryOptions, PreloadedQuery, useLazyLoadQuery, useMutation, usePreloadedQuery, useQueryLoader, VariablesOf } from 'react-relay/hooks';
import { QueuePageQuery } from './__generated__/QueuePageQuery.graphql';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { QueuePageListQuery } from './__generated__/QueuePageListQuery.graphql';
import clsx from 'clsx';
import ArrowUp from '../components/ArrowUp';
import ArrowDown from '../components/ArrowDown';
import { QueuePageVoteMutation, Vote } from './__generated__/QueuePageVoteMutation.graphql';

const query = graphql`
  query QueuePageListQuery {
    me {
      availableTokens {
        id
      }
    }
    combokensEnabled: featureEnabled(slug: "comboken")
    votingEnabled: featureEnabled(slug: "voting")
    currentStream {
      id
      status
      requests {
        id
        value
        requestedBy
        status
        song {
          name
          filepath
        }
        createdAt
        canCombo
        timeBonus
        waitingTime
        formattedValue
        formattedValueForSwoop
        myVoteType
        vote
      }
    }
  }
`;

function QueuePageRequestList({queryReference, loadQuery}: {queryReference: PreloadedQuery<QueuePageListQuery>, loadQuery: (variables: VariablesOf<QueuePageListQuery>, options?: LoadQueryOptions) => void}) {
  const data = usePreloadedQuery<QueuePageListQuery>(query, queryReference);

  const [vote, voteInFlight] = useMutation<QueuePageVoteMutation>(
    graphql`
      mutation QueuePageVoteMutation($input: VoteOnRequestInput!) {
        voteOnRequest(input: $input) {
          request {
            id
          }
        }
      }
    `
  )

  const submitVote = (requestId: string, voteType: Vote) => {
    vote({
      variables: {
        input: {
          id: requestId,
          type: voteType,
        },
      },
      onCompleted(response, errors) {
        loadQuery({}, {
          fetchPolicy: "store-and-network",
        });
      }
    })
  }

  if (!data.currentStream) {
    return <>
      <div className="bg-white text-lg rounded-lg shadow px-8 py-9 sm:px-6 text-center">
        88bitmusic isn't streaming right now.
        <div className="mt-5">
          <img className="inline" src="/bitSad.png" alt="bitSad emote" />
        </div>
        <div className="mt-5">
          <Link className="text-blue-500 hover:text-blue-800" to="/song_list">But you can stare longingly at the song list...</Link>
        </div>
      </div>
    </>;
  }

  const anyStarted = !!data.currentStream.requests.find(r => r.status === 'started');

  if (data.currentStream.requests.length === 0) {
    return <>
      <div className="bg-white text-lg rounded-lg shadow px-8 py-9 sm:px-6 text-center">
        There's nothing on the queue right now!
        <div className="mt-5">
          <Link className="text-blue-500 hover:text-blue-800" to="/song_list">A great opportunity to take a look at the song list...</Link>
        </div>
      </div>
    </>;
  }

  return <>
    <div className="divide-y divide-gray-400 align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-lg">
      {
        data.currentStream.requests.map((r, i) => {
          return <div className={clsx({"bg-white": r.status === 'pending', "bg-green-200": r.status === 'started'})} key={i}>
            <div className="w-full px-3 py-2 sm:px-6 sm:py-4 whitespace-no-wrap text-md sm:text-md leading-5 text-gray-900">
              <div className="float-right">
                <div className="text-lg text-right">
                  {r.formattedValue}
                </div>
                <div className="text-sm text-gray-700">
                  {r.requestedBy}
                  { r.canCombo && data.me && data.me.availableTokens.length > 0 && data.combokensEnabled && (
                    <>
                     <Link className="cursor-pointer text-blue-500 hover:text-blue-800" to={`/combo/${r.id}`}>+</Link>
                    </>
                  )}
                </div>
              </div>

              {
                data.me && data.votingEnabled && (
                  <>
                    <div className="float-left mr-3 -ml-2 -mt-3">
                      <div onClick={() => {
                        if (r.myVoteType === 'UP') {
                          submitVote(r.id, "NONE")
                        } else {
                          submitVote(r.id, "UP")
                        }
                      }}>
                        <ArrowUp fill={r.myVoteType === 'UP' ? 'green' : ''}/>
                      </div>
                      <div className="text-md w-8 text-center" style={{marginTop: '4px'}}>
                        {r.vote}
                      </div>
                      <div onClick={() => {
                        if (r.myVoteType === 'DOWN') {
                          submitVote(r.id, "NONE")
                        } else {
                          submitVote(r.id, "DOWN")
                        }
                      }} className="-mt-1">
                        <ArrowDown fill={r.myVoteType === 'DOWN' ? 'red' : ''}/>
                      </div>
                    </div>
                  </>
                )
              }
              <div>
                <div className="text-lg">
                  {r.song.name}
                </div>
                <div className="text-sm text-gray-700">
                  {r.waitingTime} ago {r.status === 'pending' && <span className="text-xs">({r.formattedValueForSwoop})</span>}
                </div>
              </div>
            </div>
          </div>
        })
      }
    </div>
  </>
}
function QueuePage() {
  const data = useLazyLoadQuery<QueuePageQuery>(graphql`
    query QueuePageQuery {
      me {
        availableTokens {
          id
        }
      }
    }
  `, {});

  const [
    queryReference,
    loadQuery,
    disposeQuery,
  ] = useQueryLoader<QueuePageListQuery>(query);

  useEffect(() => {
    loadQuery({});

    const handle = setInterval(() => {
      loadQuery({}, {
        fetchPolicy: "store-and-network",
      });
    }, 10000);

    return () => {
      clearInterval(handle);
    }
  }, []);

  return <>
    <header className="py-4 text-white">
      <div className="max-w-7xl ">
        <h1 className="text-3xl leading-9 font-bold text-white">
          Queue
        </h1>
        { data.me && <>
          You have {data.me?.availableTokens.length} request {data.me?.availableTokens.length == 1 ? 'token' : 'tokens'}.
        </> }
        {/* <%= content_for :subheading %> */}
      </div>
    </header>
    {queryReference != null && <QueuePageRequestList queryReference={queryReference} loadQuery={loadQuery}/>}
  </>;
}

export default QueuePage;