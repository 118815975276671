import React, { Component, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import { useParams, Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';
import { Listbox } from '@headlessui/react';
import Button from '../components/Button';
import { commitMutation } from 'react-relay';
import environment from '../utils/environment';
import { ComboPageRequestMutation } from './__generated__/ComboPageRequestMutation.graphql';
import { ComboPageBasicQuery } from './__generated__/ComboPageBasicQuery.graphql';

function ComboPage() {
  const { rid } = useParams<{rid: string}>();
  const data = useLazyLoadQuery<ComboPageBasicQuery>(graphql`
    query ComboPageBasicQuery {
      me {
        availableTokens {
          id
          value
        }
      }
      currentStream {
        status
      }
      featureEnabled(slug: "comboken")
    }
  `, {});

  let navigate = useNavigate();
  let [selectedToken, setSelectedToken] = useState(data.me?.availableTokens[0] || null)
  let [note, setNote] = useState<string | null>(null);
  let [submitRequest, requestInFlight] = useMutation<ComboPageRequestMutation>(
    graphql`
      mutation ComboPageRequestMutation($input: MakeComboInput!) {
        makeCombo(input: $input) {
          songRequest {
            id
          }
        }
      }
    `
  );

  if (!data.me) {
    window.location.pathname = '/';
  }

  if (data.me?.availableTokens.length == 0) {
    return <>
      <header className="py-4 text-white">
        <div className="max-w-7xl ">
          <h1 className="text-3xl leading-9 font-bold text-white">
            Combo
          </h1>
          You have {data.me?.availableTokens?.length} request tokens, and you thought you'd try this?
        </div>
      </header>
      <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
        Sadly you do not have any request tokens.
      </div>
    </>;
  }

  if (!data.featureEnabled) {
    return <>
      <header className="py-4 text-white">
        <div className="max-w-7xl ">
          <h1 className="text-3xl leading-9 font-bold text-white">
            Combo
          </h1>
          You have {data.me?.availableTokens?.length} request tokens
        </div>
      </header>
      <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
        Sadly combokens aren't allowed right now.
      </div>
    </>;
  }

  const submit = () => {
    submitRequest({
      variables: {
        input: {
          requestId: rid,
          requestTokenId: selectedToken?.id || "",
        },
      },
      onCompleted() {
        navigate("/");
      }
    });
  };

  let at = data.me?.availableTokens || [];

  return <>
    <header className="py-4 text-white">
      <div className="max-w-7xl ">
        <h1 className="text-3xl leading-9 font-bold text-white">
          Combo
        </h1>
        You have {data.me?.availableTokens?.length} request {data.me?.availableTokens?.length == 1 ? 'token' : 'tokens'}.
      </div>
    </header>
    <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
      <div className="mt-5">
        <label htmlFor="token" className="block text-sm leading-5 font-medium text-gray-700">Token</label>
        <select onChange={(e) => {setSelectedToken(at[parseInt(e.target.value)])}} id="token" className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5">
          {(data.me?.availableTokens || []).map((t, i) => (
            <option key={i} value={i}>{t.value}</option>
          ))}
        </select>
      </div>
      <div className="mt-5">
        <Button label={"Combo!"} onClick={submit} disabled={requestInFlight}/>
      </div>
    </div>
  </>;
}

export default ComboPage;