/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OverlayQueuePageListQueryVariables = {};
export type OverlayQueuePageListQueryResponse = {
    readonly refreshFlag: {
        readonly id: string;
        readonly shouldRefresh: boolean | null;
    } | null;
    readonly currentStream: {
        readonly id: string;
        readonly status: string;
        readonly requests: ReadonlyArray<{
            readonly id: string;
            readonly value: number;
            readonly formattedValue: string;
            readonly requestedBy: string;
            readonly status: string;
            readonly song: {
                readonly name: string;
                readonly filepath: string | null;
            };
            readonly createdAt: string;
            readonly canCombo: boolean;
            readonly waitingTime: string;
        }>;
    } | null;
};
export type OverlayQueuePageListQuery = {
    readonly response: OverlayQueuePageListQueryResponse;
    readonly variables: OverlayQueuePageListQueryVariables;
};



/*
query OverlayQueuePageListQuery {
  refreshFlag {
    id
    shouldRefresh
  }
  currentStream {
    id
    status
    requests {
      id
      value
      formattedValue
      requestedBy
      status
      song {
        name
        filepath
        id
      }
      createdAt
      canCombo
      waitingTime
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "RefreshFlag",
  "kind": "LinkedField",
  "name": "refreshFlag",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shouldRefresh",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattedValue",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requestedBy",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filepath",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canCombo",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "waitingTime",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OverlayQueuePageListQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Stream",
        "kind": "LinkedField",
        "name": "currentStream",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SongRequest",
            "kind": "LinkedField",
            "name": "requests",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SongListEntry",
                "kind": "LinkedField",
                "name": "song",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OverlayQueuePageListQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Stream",
        "kind": "LinkedField",
        "name": "currentStream",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SongRequest",
            "kind": "LinkedField",
            "name": "requests",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SongListEntry",
                "kind": "LinkedField",
                "name": "song",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f909bb89f3c0027309bffc8655989e45",
    "id": null,
    "metadata": {},
    "name": "OverlayQueuePageListQuery",
    "operationKind": "query",
    "text": "query OverlayQueuePageListQuery {\n  refreshFlag {\n    id\n    shouldRefresh\n  }\n  currentStream {\n    id\n    status\n    requests {\n      id\n      value\n      formattedValue\n      requestedBy\n      status\n      song {\n        name\n        filepath\n        id\n      }\n      createdAt\n      canCombo\n      waitingTime\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '390b207a8e01acbe033951fc606e36c0';
export default node;
