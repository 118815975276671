/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CategoryManagementPageQueryVariables = {};
export type CategoryManagementPageQueryResponse = {
    readonly me: {
        readonly isAdmin: boolean;
    } | null;
    readonly allCategories: ReadonlyArray<{
        readonly name: string;
        readonly ancestorNames: ReadonlyArray<string>;
    }>;
};
export type CategoryManagementPageQuery = {
    readonly response: CategoryManagementPageQueryResponse;
    readonly variables: CategoryManagementPageQueryVariables;
};



/*
query CategoryManagementPageQuery {
  me {
    isAdmin
    id
  }
  allCategories {
    name
    ancestorNames
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ancestorNames",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CategoryManagementPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SongListCategory",
        "kind": "LinkedField",
        "name": "allCategories",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CategoryManagementPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SongListCategory",
        "kind": "LinkedField",
        "name": "allCategories",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "155cc4f58a03ef6b3ecb1ea4e872432d",
    "id": null,
    "metadata": {},
    "name": "CategoryManagementPageQuery",
    "operationKind": "query",
    "text": "query CategoryManagementPageQuery {\n  me {\n    isAdmin\n    id\n  }\n  allCategories {\n    name\n    ancestorNames\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b1453a6bc1a9d1a2d31dfec39e2ffafc';
export default node;
