import React, { ChangeEvent, ChangeEventHandler, Component, Suspense, useEffect, useRef, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { PreloadedQuery, useLazyLoadQuery, useMutation, usePreloadedQuery, useQueryLoader, useRelayEnvironment } from 'react-relay/hooks';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';
import { Listbox } from '@headlessui/react';
import Button from '../../components/Button';
import { commitMutation } from 'react-relay';
import environment from '../../utils/environment';
import { DefaultDeserializer } from 'v8';
import { SongListManagementPageQuery, SongListManagementPageQueryResponse } from './__generated__/SongListManagementPageQuery.graphql';
import LinkIcon from '../../components/LinkIcon';
import ExternalLinkIcon from '../../components/ExternalLinkIcon';
import TableHeader from '../../components/TableHeader';
import TableHeaderColumn from '../../components/TableHeaderColumn';
import Table from '../../components/Table';
import TableBody from '../../components/TableBody';
import TableColumn from '../../components/TableColumn';
import TableRow from '../../components/TableRow';

import { Formik, Field, Form, FormikProps } from 'formik';
// import { SongListManagementPageUpdateMutation } from './__generated__/SongListManagementPageUpdateMutation.graphql';
import { SongListManagementPageSearchQuery } from './__generated__/SongListManagementPageSearchQuery.graphql';
import { SongListManagementPageDeleteSongsMutation } from './__generated__/SongListManagementPageDeleteSongsMutation.graphql';

type CheckboxCallbacks = {
  add(id: string): void;
  delete(id: string): void;
  isChecked(id: string): boolean;
}

function SongListManagementPageEntry({songListEntry, checkboxCallbacks}: {checkboxCallbacks: CheckboxCallbacks, songListEntry: SongListManagementPageSearchQuery['response']['songListSearch']['songs'][0]}) {
  const [isEditing, setIsEditing] = useState(false);

  // let [updateSheetMusic, updateSheetMusicInflight] = useMutation<SongListManagementPageUpdateMutation>(
  //   graphql`
  //     mutation SongListManagementPageUpdateMutation($input: UpdateSheetMusicInput!) {
  //       updateSheetMusic(input: $input) {
  //         sheetMusic {
  //           id
  //           name
  //           songCount
  //         }
  //       }
  //     }
  //   `
  // );

  const checkboxOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      checkboxCallbacks.add(songListEntry.id);
    } else {
      checkboxCallbacks.delete(songListEntry.id);
    }
  };

  return <TableRow>
    <Formik
      initialValues={{name: songListEntry.name, tags: songListEntry.tags}}
      onSubmit={(values, actions) => {
        // updateSheetMusic(
        //   {
        //     variables: {
        //       input: {
        //         id: sheetMusic.id,
        //         name: values.name,
        //       }
        //     },
        //     onCompleted(response, errors) {
        //       actions.setSubmitting(false);

        //       if (!errors) {
        //         setIsEditing(false);
        //       } else {
        //         alert("something went wrong, and exo is lazy and didn't expose a nice error")
        //       }
        //     }
        //   }
        // )
      }}
    >
      {({isSubmitting, resetForm, submitForm }) => <>
          <TableColumn size='small' className='text-center'>
            <input type="checkbox" onChange={checkboxOnChange} checked={checkboxCallbacks.isChecked(songListEntry.id)} value={songListEntry.id} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
          </TableColumn>
          <TableColumn >
            {
              !isEditing && (
                <div className="truncate max-w-sm">
                  {songListEntry.name}
                  {/* <a href={songListEntry.url} target="_blank" rel="noopener" className="underline text-blue-500 hover:text-blue-600">
                    <ExternalLinkIcon />
                  </a> */}
                </div>
              )
            }
            {
              isEditing && (
                <>
                  <Field type="text" name="name" className={clsx("focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md sm:text-sm border-gray-300")} />
                </>
              )
            }
          </TableColumn>
          <TableColumn>
            ({songListEntry.categoryName})
          </TableColumn>
          <TableColumn>
            {songListEntry.tags.join(", ")}
          </TableColumn>
          <TableColumn className="text-right">
            {
              isEditing && (
                <>
                  <a href="#" className={clsx("mr-4", {"text-gray-400 hover:text-gray-400": isSubmitting, "text-indigo-600 hover:text-indigo-900": !isSubmitting})} onClick={() => {
                    if (!isSubmitting) {
                      submitForm();
                    }
                  }}>Save</a>
                  <a href="#" className={clsx("mr-4", {"text-gray-400 hover:text-gray-400": isSubmitting, "text-red-600 hover:text-red-900": !isSubmitting})} onClick={() => {
                    setIsEditing(false);
                    resetForm();
                  }}>Cancel</a>
                </>
              )
            }
            {
              !isEditing && (
                <>
                  <a href="#" className="text-indigo-600 hover:text-indigo-900" onClick={(e) => {setIsEditing(true); e.preventDefault()}}>Edit</a>
                </>
              )
            }
          </TableColumn>
        </>
      }
    </Formik>
  </TableRow>;
}

function SongListTable({queryReference, checkboxCallbacks}: {queryReference: PreloadedQuery<SongListManagementPageSearchQuery>, checkboxCallbacks: CheckboxCallbacks}) {
  const data = usePreloadedQuery(query, queryReference);

  return <Table>
    <TableHeader>
      <TableHeaderColumn size="small">
      </TableHeaderColumn>
      <TableHeaderColumn className='w-12'>
        Name
      </TableHeaderColumn>
      <TableHeaderColumn>
        Current Category
      </TableHeaderColumn>
      <TableHeaderColumn>
        Tags
      </TableHeaderColumn>
      <TableHeaderColumn size='small' className='w-0.5'>
      </TableHeaderColumn>
    </TableHeader>
    <TableBody>
      {
        data.songListSearch && data.songListSearch.songs.map((songListEntry, i) => <SongListManagementPageEntry key={i} songListEntry={songListEntry} checkboxCallbacks={checkboxCallbacks}/>)
      }
    </TableBody>
  </Table>;
}

const query = graphql`
  query SongListManagementPageSearchQuery($input: String) {
    songListSearch(input: $input, count: 50000) {
      songs {
        id
        name
        categoryName
        tags
      }
    }
  }
`;

function SongListManagementPage() {
  const data = useLazyLoadQuery<SongListManagementPageQuery>(graphql`
    query SongListManagementPageQuery {
      me {
        isAdmin
      }
    }
  `, {});

  const checkedEntryIds = useRef(new Set<string>());
  const [checkedCounter, setCheckedCounter] = useState(0);

  const checkboxCallbacks: CheckboxCallbacks = {
    add(id) {
      checkedEntryIds.current.add(id);
      setCheckedCounter(i => i + 1);
    },
    delete(id) {
      checkedEntryIds.current.delete(id);
      setCheckedCounter(i => i - 1);
    },
    isChecked(id) {
      return checkedEntryIds.current.has(id);
    }
  };

  const clearAllSelections = () => {
    checkedEntryIds.current = new Set();
    setCheckedCounter(0);
  }

  const [
    queryReference,
    loadQuery,
    disposeQuery,
  ] = useQueryLoader<SongListManagementPageSearchQuery>(query);

  useEffect(() => {
    loadQuery({});
  }, [])

  const formikRef = useRef<FormikProps<{search: string}>>(null);

  let [deleteSongs, deleteSongsInflight] = useMutation<SongListManagementPageDeleteSongsMutation>(
    graphql`
      mutation SongListManagementPageDeleteSongsMutation($input: DeleteSongsInput!) {
        deleteSongs(input: $input) {
          deleted
        }
      }
    `
  );

  if (!data.me || !data.me.isAdmin) {
    window.location.pathname = '/';
  }

  const deleteSelected = () => {
    if (checkedCounter > 0) {
      if(window.confirm("Do you really want to PERMANENTLY delete those songs?")) {
        const ids = Array.from(checkedEntryIds.current);
        clearAllSelections();

        deleteSongs({
          variables: {
            input: { ids }
          },
          onCompleted() {
            loadQuery({
              input: formikRef.current?.values.search,
            }, {
              fetchPolicy: 'network-only'
            });
          }
        })
      }
    }
  }

  return <>
    <header className="py-4 text-white">
      <div className="max-w-7xl ">
        <h1 className="text-3xl leading-9 font-bold text-white">
          Song List
        </h1>
      </div>
    </header>
    <div className="bg-white shadow rounded-lg px-5 py-6 sm:px-6 mb-4">
      <Formik
        innerRef={formikRef}
        initialValues={{search: ""}}
        onSubmit={(values, actions) => {
          loadQuery({
            input: values.search
          });

          actions.setSubmitting(false);
        }}
      >
        {
          ({isSubmitting, submitForm}) => {
            return <Form>
              <div className="mt-1 flex rounded-md shadow-sm">
                <Field type="text" name="search" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-l-md sm:text-sm border-gray-300"/>
                <button type="submit" className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">Search</button>
              </div>
            </Form>
          }
        }
      </Formik>
      <br/>
      <div>
        {checkedCounter} {checkedCounter === 1 ? 'song' : 'songs'} selected { checkedCounter > 0 && <Button onClick={clearAllSelections} label="Clear All" size='xs' color='white'/>}
        <span className="float-right"><Button onClick={deleteSelected} label='Remove' size='xs' color='red'/></span>
      </div>
    </div>
    {queryReference != null && <Suspense fallback={null}>
      <SongListTable queryReference={queryReference} checkboxCallbacks={checkboxCallbacks}/>
    </Suspense>}
  </>;
}

export default SongListManagementPage;