
import React, { Component, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation, useRelayEnvironment } from 'react-relay/hooks';
import moment from 'moment';
import Button from '../../components/Button';
import { ManagementPageQuery } from './__generated__/ManagementPageQuery.graphql';
import { Link, useMatch } from 'react-router-dom';

function ManagementPage() {
  const data = useLazyLoadQuery<ManagementPageQuery>(graphql`
    query ManagementPageQuery {
      me {
        username
        isAdmin
      }
    }
  `, {});


  if (!data.me || !data.me.isAdmin) {
    window.location.pathname = '/';
  }
  return <>
    <header className="py-4 text-white">
      <div className="max-w-7xl ">
        <h1 className="text-3xl leading-9 font-bold text-white">
          Management
        </h1>
      </div>
    </header>
    <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mt-5">
      <ul>
        <li>
          <Link to={'/management/tokens'} className={'text-blue-500 hover:text-blue-900'}>Tokens</Link>
        </li>
        <li>
          <Link to={'/management/feature_toggles'} className={'text-blue-500 hover:text-blue-900'}>Feature Toggles</Link>
        </li>
        <li>
          <Link to={'/management/configurable_values'} className={'text-blue-500 hover:text-blue-900'}>Configurable Values</Link>
        </li>
        <li>
          <Link to={'/management/commands'} className={'text-blue-500 hover:text-blue-900'}>Commands</Link>
        </li>

        <li>
          <Link to={'/management/song_list'} className={'text-blue-500 hover:text-blue-900'}>Song List</Link>
        </li>

        {data.me?.username === "exosnecko" && <>
          <li>
            <Link to={'/management/upload_music'} className={'text-blue-500 hover:text-blue-900'}>Upload Music</Link>
          </li>
          <li>
            <Link to={'/management/sheet_music'} className={'text-blue-500 hover:text-blue-900'}>Sheet Music</Link>
          </li>
          <li>
            <Link to={'/management/categories'} className={'text-blue-500 hover:text-blue-900'}>Categories</Link>
          </li>
        </>}
      </ul>
    </div>
  </>;
}

export default ManagementPage;