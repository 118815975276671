/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ToggleFeatureInput = {
    id: string;
    clientMutationId?: string | null;
};
export type FeatureTogglePageToggleMutationVariables = {
    input: ToggleFeatureInput;
};
export type FeatureTogglePageToggleMutationResponse = {
    readonly toggleFeature: {
        readonly featureToggle: {
            readonly id: string;
        };
    } | null;
};
export type FeatureTogglePageToggleMutation = {
    readonly response: FeatureTogglePageToggleMutationResponse;
    readonly variables: FeatureTogglePageToggleMutationVariables;
};



/*
mutation FeatureTogglePageToggleMutation(
  $input: ToggleFeatureInput!
) {
  toggleFeature(input: $input) {
    featureToggle {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ToggleFeaturePayload",
    "kind": "LinkedField",
    "name": "toggleFeature",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FeatureToggle",
        "kind": "LinkedField",
        "name": "featureToggle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeatureTogglePageToggleMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeatureTogglePageToggleMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cf7614bc554615f04add27fd499495d8",
    "id": null,
    "metadata": {},
    "name": "FeatureTogglePageToggleMutation",
    "operationKind": "mutation",
    "text": "mutation FeatureTogglePageToggleMutation(\n  $input: ToggleFeatureInput!\n) {\n  toggleFeature(input: $input) {\n    featureToggle {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ab09a84d33bdf055cbdb0fcdc2bee47f';
export default node;
