/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SongListManagementPageSearchQueryVariables = {
    input?: string | null;
};
export type SongListManagementPageSearchQueryResponse = {
    readonly songListSearch: {
        readonly songs: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly categoryName: string;
            readonly tags: ReadonlyArray<string>;
        }>;
    };
};
export type SongListManagementPageSearchQuery = {
    readonly response: SongListManagementPageSearchQueryResponse;
    readonly variables: SongListManagementPageSearchQueryVariables;
};



/*
query SongListManagementPageSearchQuery(
  $input: String
) {
  songListSearch(input: $input, count: 50000) {
    songs {
      id
      name
      categoryName
      tags
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "count",
        "value": 50000
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SongListSearch",
    "kind": "LinkedField",
    "name": "songListSearch",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SongListEntry",
        "kind": "LinkedField",
        "name": "songs",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "categoryName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SongListManagementPageSearchQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SongListManagementPageSearchQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "54266ccffcb69543030b0f3593b8b193",
    "id": null,
    "metadata": {},
    "name": "SongListManagementPageSearchQuery",
    "operationKind": "query",
    "text": "query SongListManagementPageSearchQuery(\n  $input: String\n) {\n  songListSearch(input: $input, count: 50000) {\n    songs {\n      id\n      name\n      categoryName\n      tags\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '36352679b35699575756cd19933aa126';
export default node;
