/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type StreamViewPageFinishStreamMutationVariables = {};
export type StreamViewPageFinishStreamMutationResponse = {
    readonly finishStream: {
        readonly success: boolean;
    } | null;
};
export type StreamViewPageFinishStreamMutation = {
    readonly response: StreamViewPageFinishStreamMutationResponse;
    readonly variables: StreamViewPageFinishStreamMutationVariables;
};



/*
mutation StreamViewPageFinishStreamMutation {
  finishStream(input: {}) {
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {}
      }
    ],
    "concreteType": "FinishStreamPayload",
    "kind": "LinkedField",
    "name": "finishStream",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": "finishStream(input:{})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StreamViewPageFinishStreamMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StreamViewPageFinishStreamMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "29aa1782d4ff46c6a72b7fa756904215",
    "id": null,
    "metadata": {},
    "name": "StreamViewPageFinishStreamMutation",
    "operationKind": "mutation",
    "text": "mutation StreamViewPageFinishStreamMutation {\n  finishStream(input: {}) {\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = '267182c6a0490749ff7cf552a3dcd947';
export default node;
