import React, { ReactElement } from 'react';
import { Link as LLink, useMatch } from 'react-router-dom';

type HeaderLinkProps = {
  to: string
  mobile?: boolean
};

export const HeaderLink: React.FC<HeaderLinkProps> = (props) => {
  let match = useMatch({
    path: props.to,
    end: true,
  });

  let classes = "px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700";

  if (match) {
    classes = "px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700";
  }

  if (props.mobile) {
    classes = "block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700";

    if (match) {
      classes = "block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700";
    }
  }

  return <LLink to={props.to} className={classes}>
    {props.children}
  </LLink>;
};

export default HeaderLink;