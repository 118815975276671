import React, { Component, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation, useRelayEnvironment } from 'react-relay/hooks';
import moment from 'moment';
import Button from '../../components/Button';
import { Link, useMatch } from 'react-router-dom';
import { FeatureTogglePageQuery } from './__generated__/FeatureTogglePageQuery.graphql';
import clsx from 'clsx';
import { FeatureTogglePageToggleMutation } from './__generated__/FeatureTogglePageToggleMutation.graphql';

function FeatureTogglePage() {
  const [fetchKey, setFetchKey] = useState(0);

  const data = useLazyLoadQuery<FeatureTogglePageQuery>(graphql`
    query FeatureTogglePageQuery {
      me {
        isAdmin
      }
      featureToggles {
        id
        label
        enabled
      }
    }
  `, {}, {fetchKey: fetchKey, fetchPolicy: 'store-and-network'});

  let [toggleFeature, toggleFeatureInFlight] = useMutation<FeatureTogglePageToggleMutation>(
    graphql`
      mutation FeatureTogglePageToggleMutation($input: ToggleFeatureInput!) {
        toggleFeature(input: $input) {
          featureToggle {
            id
          }
        }
      }
    `
  );

  if (!data.me || !data.me.isAdmin) {
    window.location.pathname = '/';
  }

  const submitToggle = (id: string) => {
    toggleFeature({
      variables: {
        input: {
          id: id
        },
      },
      onCompleted(response, errors) {
        setFetchKey(prev => prev + 1);
      }
    });
  };

  return <>
    <header className="py-4 text-white">
      <div className="max-w-7xl ">
        <h1 className="text-3xl leading-9 font-bold text-white">
          Feature Toggles
        </h1>
      </div>
    </header>
    <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mt-5">
      {
        data.featureToggles.map((toggle, i) =>
          <div key={i} className="py-2">
            <div className="flex items-center">
              <button onClick={() => submitToggle(toggle.id)} type="button" className={clsx(
                { "bg-gray-200": !toggle.enabled, "bg-indigo-600": toggle.enabled },
                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              )}>
                {/* <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" --> */}
                <span className={clsx(
                  {"translate-x-5": toggle.enabled, "translate-x-0": !toggle.enabled},
                  "translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                )}></span>
              </button>
              <span className="ml-3">
                <span className="text-sm font-medium text-gray-900">{toggle.label}</span>
              </span>
            </div>
          </div>
        )
      }
    </div>
  </>;
}

export default FeatureTogglePage;