/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ComboPageBasicQueryVariables = {};
export type ComboPageBasicQueryResponse = {
    readonly me: {
        readonly availableTokens: ReadonlyArray<{
            readonly id: string;
            readonly value: number;
        }>;
    } | null;
    readonly currentStream: {
        readonly status: string;
    } | null;
    readonly featureEnabled: boolean;
};
export type ComboPageBasicQuery = {
    readonly response: ComboPageBasicQueryResponse;
    readonly variables: ComboPageBasicQueryVariables;
};



/*
query ComboPageBasicQuery {
  me {
    availableTokens {
      id
      value
    }
    id
  }
  currentStream {
    status
    id
  }
  featureEnabled(slug: "comboken")
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "RequestToken",
  "kind": "LinkedField",
  "name": "availableTokens",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "slug",
      "value": "comboken"
    }
  ],
  "kind": "ScalarField",
  "name": "featureEnabled",
  "storageKey": "featureEnabled(slug:\"comboken\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ComboPageBasicQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Stream",
        "kind": "LinkedField",
        "name": "currentStream",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ComboPageBasicQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Stream",
        "kind": "LinkedField",
        "name": "currentStream",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "62350e3be538f9f04ab72febcf49b5cf",
    "id": null,
    "metadata": {},
    "name": "ComboPageBasicQuery",
    "operationKind": "query",
    "text": "query ComboPageBasicQuery {\n  me {\n    availableTokens {\n      id\n      value\n    }\n    id\n  }\n  currentStream {\n    status\n    id\n  }\n  featureEnabled(slug: \"comboken\")\n}\n"
  }
};
})();
(node as any).hash = '929e08e1b2199267ae3954178a1b59e5';
export default node;
