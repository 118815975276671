/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type StreamViewPageQueryVariables = {};
export type StreamViewPageQueryResponse = {
    readonly me: {
        readonly isAdmin: boolean;
        readonly availableTokens: ReadonlyArray<{
            readonly id: string;
        }>;
    } | null;
    readonly charityOnly: boolean;
    readonly currentStream: {
        readonly id: string;
        readonly status: string;
        readonly requests: ReadonlyArray<{
            readonly id: string;
            readonly value: number;
            readonly formattedValue: string;
            readonly requestedBy: string;
            readonly firstRequester: string;
            readonly note: string | null;
            readonly status: string;
            readonly timeBonus: number;
            readonly song: {
                readonly name: string;
                readonly filepath: string | null;
            };
            readonly sheetMusicUrl: string | null;
            readonly createdAt: string;
        }>;
    } | null;
};
export type StreamViewPageQuery = {
    readonly response: StreamViewPageQueryResponse;
    readonly variables: StreamViewPageQueryVariables;
};



/*
query StreamViewPageQuery {
  me {
    isAdmin
    availableTokens {
      id
    }
    id
  }
  charityOnly: featureEnabled(slug: "charity_only")
  currentStream {
    id
    status
    requests {
      id
      value
      formattedValue
      requestedBy
      firstRequester
      note
      status
      timeBonus
      song {
        name
        filepath
        id
      }
      sheetMusicUrl
      createdAt
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "RequestToken",
  "kind": "LinkedField",
  "name": "availableTokens",
  "plural": true,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = {
  "alias": "charityOnly",
  "args": [
    {
      "kind": "Literal",
      "name": "slug",
      "value": "charity_only"
    }
  ],
  "kind": "ScalarField",
  "name": "featureEnabled",
  "storageKey": "featureEnabled(slug:\"charity_only\")"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattedValue",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requestedBy",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstRequester",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeBonus",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filepath",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sheetMusicUrl",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StreamViewPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Stream",
        "kind": "LinkedField",
        "name": "currentStream",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SongRequest",
            "kind": "LinkedField",
            "name": "requests",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v4/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SongListEntry",
                "kind": "LinkedField",
                "name": "song",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StreamViewPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Stream",
        "kind": "LinkedField",
        "name": "currentStream",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SongRequest",
            "kind": "LinkedField",
            "name": "requests",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v4/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SongListEntry",
                "kind": "LinkedField",
                "name": "song",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a7b5000408e7ffb3bf75ff0150195edc",
    "id": null,
    "metadata": {},
    "name": "StreamViewPageQuery",
    "operationKind": "query",
    "text": "query StreamViewPageQuery {\n  me {\n    isAdmin\n    availableTokens {\n      id\n    }\n    id\n  }\n  charityOnly: featureEnabled(slug: \"charity_only\")\n  currentStream {\n    id\n    status\n    requests {\n      id\n      value\n      formattedValue\n      requestedBy\n      firstRequester\n      note\n      status\n      timeBonus\n      song {\n        name\n        filepath\n        id\n      }\n      sheetMusicUrl\n      createdAt\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '829b8e9012e5a4ecedef59a6dc78e460';
export default node;
