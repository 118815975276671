import React, { Component, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation, useRelayEnvironment } from 'react-relay/hooks';
import moment from 'moment';
import Button from '../../components/Button';
import { Link, useNavigate, useMatch } from 'react-router-dom';
import { CategoryManagementPageQuery } from './__generated__/CategoryManagementPageQuery.graphql';
import ChevronRight from '../../components/ChevronRight';

function CategoryManagementPage() {
  const data = useLazyLoadQuery<CategoryManagementPageQuery>(graphql`
    query CategoryManagementPageQuery {
      me {
        isAdmin
      }
      allCategories {
        name
        ancestorNames
      }
    }
  `, {});

  let navigate = useNavigate();

  if (!data.me || !data.me.isAdmin) {
    window.location.pathname = '/';
  }

  return <>
    <header className="py-4 text-white">
      <div className="max-w-7xl ">
        <h1 className="text-3xl leading-9 font-bold text-white">
          Category Management
        </h1>
      </div>
    </header>
    <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mt-5">
    </div>
    <div className="mt-5 bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {
          data.allCategories.map((category) => <>
            <li>
              <a href="#" className="block hover:bg-gray-50">
                <div className="px-4 py-4 flex items-center sm:px-6">
                  <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                    <div>
                      <div className="flex text-sm font-medium text-indigo-600 truncate">
                        <p>{category.ancestorNames.map(() => <>&nbsp;&nbsp;&nbsp;&nbsp;</>)}{category.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </>)
        }
      </ul>
    </div>
  </>;
}

export default CategoryManagementPage;