/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type StreamViewPageUnpauseStreamMutationVariables = {};
export type StreamViewPageUnpauseStreamMutationResponse = {
    readonly unpauseStream: {
        readonly success: boolean;
    } | null;
};
export type StreamViewPageUnpauseStreamMutation = {
    readonly response: StreamViewPageUnpauseStreamMutationResponse;
    readonly variables: StreamViewPageUnpauseStreamMutationVariables;
};



/*
mutation StreamViewPageUnpauseStreamMutation {
  unpauseStream(input: {}) {
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {}
      }
    ],
    "concreteType": "UnpauseStreamPayload",
    "kind": "LinkedField",
    "name": "unpauseStream",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": "unpauseStream(input:{})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StreamViewPageUnpauseStreamMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StreamViewPageUnpauseStreamMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "1c6e170ff31f5185943fa99d2c6bf912",
    "id": null,
    "metadata": {},
    "name": "StreamViewPageUnpauseStreamMutation",
    "operationKind": "mutation",
    "text": "mutation StreamViewPageUnpauseStreamMutation {\n  unpauseStream(input: {}) {\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = '3e1296bdbde7bceb08bdf7daded004cf';
export default node;
