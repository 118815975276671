import React, { Component, Suspense, useEffect, useRef, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { PreloadedQuery, useLazyLoadQuery, useMutation, usePreloadedQuery, useQueryLoader, useRelayEnvironment } from 'react-relay/hooks';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment';
import clsx from 'clsx';
import { Listbox } from '@headlessui/react';
import Button from '../../components/Button';
import { commitMutation } from 'react-relay';
import environment from '../../utils/environment';
import { DefaultDeserializer } from 'v8';
import { SheetMusicsPageQuery, SheetMusicsPageQueryResponse } from './__generated__/SheetMusicsPageQuery.graphql';
import LinkIcon from '../../components/LinkIcon';
import ExternalLinkIcon from '../../components/ExternalLinkIcon';
import TableHeader from '../../components/TableHeader';
import TableHeaderColumn from '../../components/TableHeaderColumn';
import Table from '../../components/Table';
import TableBody from '../../components/TableBody';
import TableColumn from '../../components/TableColumn';
import TableRow from '../../components/TableRow';

import { Formik, Field, Form } from 'formik';
import { SheetMusicsPageUpdateMutation } from './__generated__/SheetMusicsPageUpdateMutation.graphql';
import { SheetMusicsPageSearchQuery } from './__generated__/SheetMusicsPageSearchQuery.graphql';

function SheetMusicPageEntry({sheetMusic}: {sheetMusic: SheetMusicsPageSearchQuery['response']['sheetMusicSearch'][0]}) {
  const [isEditing, setIsEditing] = useState(false);

  let [updateSheetMusic, updateSheetMusicInflight] = useMutation<SheetMusicsPageUpdateMutation>(
    graphql`
      mutation SheetMusicsPageUpdateMutation($input: UpdateSheetMusicInput!) {
        updateSheetMusic(input: $input) {
          sheetMusic {
            id
            name
            songCount
          }
        }
      }
    `
  );

  return <TableRow>
    <Formik
      initialValues={{name: sheetMusic.name}}
      onSubmit={(values, actions) => {
        updateSheetMusic(
          {
            variables: {
              input: {
                id: sheetMusic.id,
                name: values.name,
              }
            },
            onCompleted(response, errors) {
              actions.setSubmitting(false);

              if (!errors) {
                setIsEditing(false);
              } else {
                alert("something went wrong, and exo is lazy and didn't expose a nice error")
              }
            }
          }
        )
      }}
    >
      {({isSubmitting, resetForm, submitForm }) => <>
          <TableColumn size='small' className='text-center'>
            <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
          </TableColumn>
          <TableColumn>
            {
              !isEditing && (
                <>
                  {sheetMusic.name}
                  <a href={sheetMusic.url} target="_blank" rel="noopener" className="underline text-blue-500 hover:text-blue-600">
                    <ExternalLinkIcon />
                  </a>
                </>
              )
            }
            {
              isEditing && (
                <>
                  <Field type="text" name="name" className={clsx("focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md sm:text-sm border-gray-300")} />
                </>
              )
            }
          </TableColumn>
          <TableColumn>
            {sheetMusic.songCount}
          </TableColumn>
          <TableColumn>
            {sheetMusic.addedByUser?.username}
          </TableColumn>
          <TableColumn className='font-mono'>
            {sheetMusic.createdAt}
          </TableColumn>
          <TableColumn className="text-right">
            {
              isEditing && (
                <>
                  <a href="#" className={clsx("mr-4", {"text-gray-400 hover:text-gray-400": isSubmitting, "text-indigo-600 hover:text-indigo-900": !isSubmitting})} onClick={() => {
                    if (!isSubmitting) {
                      submitForm();
                    }
                  }}>Save</a>
                  <a href="#" className={clsx("mr-4", {"text-gray-400 hover:text-gray-400": isSubmitting, "text-red-600 hover:text-red-900": !isSubmitting})} onClick={() => {
                    setIsEditing(false);
                    resetForm();
                  }}>Cancel</a>
                </>
              )
            }
            {
              !isEditing && (
                <>
                  <a href="#" className="text-indigo-600 hover:text-indigo-900" onClick={() => setIsEditing(true)}>Edit</a>
                </>
              )
            }
          </TableColumn>
        </>
      }
    </Formik>
  </TableRow>;
}

function SheetMusicTable({queryReference}: {queryReference: PreloadedQuery<SheetMusicsPageSearchQuery>}) {
  const data = usePreloadedQuery(query, queryReference);

  return <Table>
    <TableHeader>
      <TableHeaderColumn size="small">
      </TableHeaderColumn>
      <TableHeaderColumn>
        Name
        </TableHeaderColumn>
      <TableHeaderColumn>
        Song Count
        </TableHeaderColumn>
      <TableHeaderColumn>
        Added By
        </TableHeaderColumn>
      <TableHeaderColumn>
        Created At
        </TableHeaderColumn>
      <TableHeaderColumn size='small' className='w-0.5'>
      </TableHeaderColumn>
    </TableHeader>
    <TableBody>
      {
        data.sheetMusicSearch.map((sheetMusic, i) => <SheetMusicPageEntry key={i} sheetMusic={sheetMusic} />)
      }
    </TableBody>
  </Table>;
}

const query = graphql`
  query SheetMusicsPageSearchQuery($input: String) {
    sheetMusicSearch(input: $input) {
      id
      name
      url
      createdAt
      addedByUser {
        username
      }
      songCount
    }
  }
`;

function SheetMusicsPage() {
  const data = useLazyLoadQuery<SheetMusicsPageQuery>(graphql`
    query SheetMusicsPageQuery {
      me {
        isAdmin
      }
    }
  `, {});

  const [
    queryReference,
    loadQuery,
    disposeQuery,
  ] = useQueryLoader<SheetMusicsPageSearchQuery>(query);

  useEffect(() => {
    loadQuery({});
  }, [])

  if (!data.me || !data.me.isAdmin) {
    window.location.pathname = '/';
  }

  return <>
    <header className="py-4 text-white">
      <div className="max-w-7xl ">
        <h1 className="text-3xl leading-9 font-bold text-white">
          Sheet Music
        </h1>
      </div>
    </header>
    <div className="bg-white shadow rounded-lg px-5 py-6 sm:px-6 mb-4">
      <Formik
        initialValues={{search: null}}
        onSubmit={(values, actions) => {
          loadQuery({
            input: values.search
          });

          actions.setSubmitting(false);
        }}
      >
        {
          ({isSubmitting, submitForm}) => {
            return <Form>
              <div className="mt-1 flex rounded-md shadow-sm">
                <Field type="text" name="search" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-l-md sm:text-sm border-gray-300"/>
                <button type="submit" className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">Search</button>
              </div>
            </Form>
          }
        }
      </Formik>
    </div>
    {queryReference != null && <Suspense fallback={null}>
      <SheetMusicTable queryReference={queryReference} />
    </Suspense>}
  </>;
}

export default SheetMusicsPage;