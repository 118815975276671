
import React, { Component, Suspense, useEffect, useRef, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { PreloadedQuery, useLazyLoadQuery, usePreloadedQuery, useQueryLoader } from 'react-relay/hooks';
import { Link, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { SongListPageQuery } from './__generated__/SongListPageQuery.graphql';
import clsx from 'clsx';
import environment from '../utils/environment';
import { SongListPage2Query } from './__generated__/SongListPage2Query.graphql';
import { SongListPageSearchQuery } from './__generated__/SongListPageSearchQuery.graphql';
import { Field, Form, Formik, FormikProps } from 'formik';
import Table from '../components/Table';
import TableHeader from '../components/TableHeader';
import TableBody from '../components/TableBody';
import TableHeaderColumn from '../components/TableHeaderColumn';
import TableRow from '../components/TableRow';
import TableColumn from '../components/TableColumn';
import Button from '../components/Button';

type CategoryProps = {
  readonly id: string;
  readonly name: string;
  readonly subcategories?: readonly CategoryProps[];
  readonly songs: readonly {readonly name: string, readonly id: string, readonly sheetMusicUrl?: string | null}[];
  stripe?: boolean;
  showChoose?: boolean;
  isAdmin?: boolean;
}

function Category(props: CategoryProps) {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = (e: React.MouseEvent) => {
    setIsOpen(!isOpen);
    e.stopPropagation();
  }

  return <div className="w-full px-3 py-2 sm:px-6 sm:py-4 whitespace-no-wrap text-md sm:text-md leading-5 text-gray-900" onClick={onClick}>
    <span className="cursor-pointer float-right">
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
    </span>
    <span className="cursor-pointer">
      {props.name}
    </span>
    <div className={clsx("pt-4", {"hidden": !isOpen})}>
      <div className="divide-y divide-gray-400 align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-lg">
        {(props.subcategories || []).map((c) => {
          return <div className={clsx({"bg-white": !props.stripe, "bg-gray-100": props.stripe})}>
            {<Category id={c.id} name={c.name} subcategories={[...(c.subcategories || [])]} songs={[...c.songs]} isAdmin={props.isAdmin} stripe={!props.stripe} showChoose={props.showChoose} />}
          </div>;
        })}
        {(props.songs || []).map((s) => {
          return <div className={clsx("whitespace-normal sm:whitespace-no-wrap px-3 py-3 sm:px-6 sm:py-6 text-md", {"bg-white": !props.stripe, "bg-gray-100": props.stripe})}>
            { (!props.isAdmin || !s.sheetMusicUrl) && s.name}
            { props.isAdmin && s.sheetMusicUrl && <a className="underline text-blue-500 hover:text-blue-600" href={s.sheetMusicUrl}>
              {s.name}
            </a>}
            <div className={clsx("sm:float-right mt-2 sm:mt-0", {"hidden": !props.showChoose})}>
              <a href={`/requests/new/${s.id}`} className="text-blue-500 hover:text-blue-900">Choose</a>
            </div>
          </div>;
        })}
      </div>
    </div>
  </div>
}

function OldSongListPage() {
  const data = useLazyLoadQuery<SongListPageQuery>(graphql`
    query SongListPageQuery {
      me {
        availableTokens {
          id
        }
        isAdmin
      }
      currentStream {
        id
      }
      categories {
        id
        name
        subcategories {
          id
          name
          subcategories {
            id
            name
            subcategories {
              id
              name
              subcategories {
                id
                name
                songs {
                  id
                  name
                  sheetMusicUrl
                }
              }
              songs {
                id
                name
                sheetMusicUrl
              }
            }
            songs {
              id
              name
              sheetMusicUrl
            }
          }
          songs {
            id
            name
            sheetMusicUrl
          }
        }
        songs {
          id
          name
          sheetMusicUrl
        }
      }
    }
  `, {});

  return <>
    <header className="py-4 text-white">
      <div className="max-w-7xl ">
        <h1 className="text-3xl leading-9 font-bold text-white">
          Song List
          </h1>
          You have {data.me?.availableTokens.length} request {data.me?.availableTokens.length == 1 ? 'token' : 'tokens'}.
          {/* <%= content_for :subheading %> */}
      </div>
    </header>
    <div className="divide-y divide-gray-400 align-middle min-w-full overflow-x-auto overflow-hidden sm:rounded-lg">
      {data.categories.map((c) => {
        let sc = (c.subcategories || []).map(c => {
          return {...c};
        });

        return <div className="bg-white">
          {<Category id={c.id} name={c.name} subcategories={sc} songs={[...c.songs]} stripe={true} isAdmin={data.me?.isAdmin} showChoose={(data.me && data.me.availableTokens.length > 0) || false} />}
        </div>;
      })}
    </div>
  </>;
}

const query = graphql`
  query SongListPageSearchQuery($input: String, $offset: Int = 0) {
    songListSearch(input: $input, count: 100, offset: $offset) {
      songs {
        id
        name
        categoryName
        tags
      }
      offset
    }
  }
`;

function SongListEntry(props: {songListEntry: SongListPageSearchQuery['response']['songListSearch']['songs'][0], withTag: (tag: string) => void}) {
  return <TableRow>
    <TableColumn><a href={`/requests/new/${props.songListEntry.id}`} className="text-blue-500 hover:text-blue-900">{props.songListEntry.name}</a></TableColumn>
    <TableColumn>{props.songListEntry.tags.map(t => <a href="#" className="rounded-full border-2 ml-1 p-1 text-xs whitespace-nowrap" onClick={(e) => {props.withTag(t); e.preventDefault()}}>{t}</a>)}</TableColumn>
  </TableRow>;
}

function SongListTable(props: {queryReference: PreloadedQuery<SongListPageSearchQuery>, withOffset: (offset: number) => void, withTag: (tag: string) => void}) {
  const data = usePreloadedQuery(query, props.queryReference);

  const setOffset = () => {
    props.withOffset(data.songListSearch.offset + 100);
  };

  return <>
    <Table>
      <TableHeader>
        <TableHeaderColumn >
          Name
        </TableHeaderColumn>
        <TableHeaderColumn>
          Tags
        </TableHeaderColumn>
        {/* <TableHeaderColumn>
        </TableHeaderColumn> */}
      </TableHeader>
      <TableBody>
        {
          data.songListSearch && data.songListSearch.songs.map((songListEntry, i) => <SongListEntry key={i} songListEntry={songListEntry} withTag={props.withTag} />)
        }
      </TableBody>
    </Table>
    <br/>
    {data.songListSearch.songs.length === 100 && <div className='float-right'><Button onClick={setOffset} label={'More'}/></div>}
    {data.songListSearch.offset > 0 && <div><Button color='white' onClick={() => props.withOffset(data.songListSearch.offset - 100)} label={'Back'}/></div>}
  </>;
}

function NewSongListPage({availableTokenCount, loggedIn}: {availableTokenCount: number, loggedIn: boolean}) {
  const [
    queryReference,
    loadQuery,
    disposeQuery,
  ] = useQueryLoader<SongListPageSearchQuery>(query);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    loadQuery({
      input: searchParams.get('s') ?? '',
      offset: parseInt(searchParams.get('o') ?? '0', 10),
    });
  }, [searchParams.get('s'), searchParams.get('o')])

  const formikRef = useRef<FormikProps<{search: string}>>(null);

  const withOffset = (offset: number) => {
    setSearchParams({
      s: searchParams.get('s') ?? '',
      o: offset.toString(),
    });
  };

  const withTag = (tag: string) => {
    const s = (searchParams.get('s') ?? '') + ` tag:"${tag}"`;

    setSearchParams({
      s: s,
      o: '0',
    });

    formikRef.current?.setValues({
      search: s
    });
  }

  return <>
    <header className="py-4 text-white">
      <div className="max-w-7xl ">
        <h1 className="text-3xl leading-9 font-bold text-white">
          Song List
          </h1>
          {loggedIn && <>You have {availableTokenCount} request {availableTokenCount == 1 ? 'token' : 'tokens'}.</>}
          {!loggedIn && <>You aren't logged in!</>}
          {/* <%= content_for :subheading %> */}
      </div>
    </header>
    <div className="bg-white shadow rounded-lg px-5 py-6 sm:px-6 mb-4">
      <Formik
        innerRef={formikRef}
        initialValues={{search: searchParams.get('s') ?? ''}}
        onSubmit={(values, actions) => {
          setSearchParams({
            s: values.search,
            o: '0',
          });

          actions.setSubmitting(false);
        }}
      >
        {
          ({isSubmitting, submitForm}) => {
            return <Form>
              <div className="mt-1 flex rounded-md shadow-sm">
                <Field type="text" name="search" className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-l-md sm:text-sm border-gray-300"/>
                <button type="submit" className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">Search</button>
              </div>
            </Form>
          }
        }
      </Formik>
    </div>
    {queryReference != null && <Suspense fallback={null}>
      <SongListTable queryReference={queryReference} withOffset={withOffset} withTag={withTag} />
    </Suspense>}
  </>;
}

function SongListPage() {
  const data = useLazyLoadQuery<SongListPage2Query>(graphql`
    query SongListPage2Query {
      me {
        availableTokens {
          id
        }
        isAdmin
      }
      newList: featureEnabled(slug: "new_list")
    }
  `, {});

  if (data.newList) {
    return <NewSongListPage availableTokenCount={data.me?.availableTokens.length ?? 0} loggedIn={!!data.me}/>;
  } else {
    return <OldSongListPage/>;
  }
}

export default SongListPage;