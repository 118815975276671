import clsx from 'clsx';
import React, { ReactElement } from 'react';

const TableHeaderColumn: React.FC<{size?: 'small', className?: string}> = ({ children, size, className }) => {
  if (!children) {
    const style: React.CSSProperties = {};

    return <th scope="col" className={clsx({"px-6": size !== 'small', "pl-6": size === 'small'}, className, "relative py-3")} style={style}>
      {children}
    </th>;
  }

  return <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider">
    {children}
  </th>;
}

export default TableHeaderColumn;