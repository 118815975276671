import React, { Suspense, useEffect, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { LoadQueryOptions, PreloadedQuery, useLazyLoadQuery, usePreloadedQuery, useQueryLoader, VariablesOf } from 'react-relay/hooks';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { OverlayQueuePageListQuery } from './__generated__/OverlayQueuePageListQuery.graphql';
import clsx from 'clsx';

const query = graphql`
  query OverlayQueuePageListQuery {
    refreshFlag {
      id
      shouldRefresh
    }
    currentStream {
      id
      status
      requests {
        id
        value
        formattedValue
        requestedBy
        status
        song {
          name
          filepath
        }
        createdAt
        canCombo
        waitingTime
      }
    }
  }
`;

function OverlayQueuePageRequestList({queryReference, loadQuery}: {queryReference: PreloadedQuery<OverlayQueuePageListQuery>, loadQuery: (variables: VariablesOf<OverlayQueuePageListQuery>, options?: LoadQueryOptions) => void}) {
  const data = usePreloadedQuery<OverlayQueuePageListQuery>(query, queryReference);
  const requests = data.currentStream?.requests || [];

  const [alreadyChecked, setAlreadyChecked] = useState(false);

  useEffect(() => {
    if (alreadyChecked) {
      if (data.refreshFlag?.shouldRefresh) {
        window.location.reload();
      }
    } else {
      setAlreadyChecked(true);
    }
  }, [data.refreshFlag?.id]);

  return <>
    <div style={{height: 234, position: 'relative'}}>
      <div className="bg-gray-900 divide-y divide-gray-500 align-middle min-w-full overflow-x-auto overflow-hidden" style={{height: 234, position: 'relative'}}>
        {
          requests.map((r, i) => {
            return <div className={clsx({ "bg-gray-800": r.status === 'pending', "bg-orange-800": r.status === 'started' })} key={i}>
              <div className="w-full px-4 py-3 whitespace-no-wrap text-md sm:text-md leading-5 text-gray-300">
                <div className="float-right">
                  <div className="text-lg text-right">
                    {r.formattedValue}
                  </div>
                  <div className="text-sm text-gray-500">
                    {r.requestedBy}
                  </div>
                </div>
                <div>
                  <div className="text-lg">
                    {r.song.name}
                  </div>
                  <div className="text-sm text-gray-500">
                    {moment(r.createdAt).fromNow()}
                  </div>
                </div>
              </div>
            </div>
          })
        }
      </div>
      {data.currentStream?.status === 'started' ?
        <div style={{ bottom: '23px', position: 'absolute', width: '100%', textShadow: '0 0 24px rgba(0,0,0,1), 0 0px 12px rgba(0,0,0,1), 0 0px 6px rgba(0,0,0,1)'}} className="border-t-0 text-green-400 text-lg px-4 text-right">
          Requests Open
        </div> :
        <div style={{ bottom: '23px', position: 'absolute', width: '100%', textShadow: '0 0 24px rgba(0,0,0,1), 0 0px 12px rgba(0,0,0,1), 0 0px 6px rgba(0,0,0,1)' }} className="border-t-0 text-red-400 text-lg px-4 text-right">
          Requests Closed
        </div>
      }
    </div>
  </>
}

function OverlayQueuePage() {
  const [
    queryReference,
    loadQuery,
    disposeQuery,
  ] = useQueryLoader<OverlayQueuePageListQuery>(query);

  useEffect(() => {
    loadQuery({});

    const handle = setInterval(() => {
      loadQuery({}, {
        fetchPolicy: "store-and-network",
      });
    }, 10000);

    return () => {
      clearInterval(handle);
    }
  }, []);
  return <>
    <Suspense fallback={<></>}>
      {queryReference != null && <OverlayQueuePageRequestList queryReference={queryReference} loadQuery={loadQuery}/>}
    </Suspense>
  </>;
}

export default OverlayQueuePage;