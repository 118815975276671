import React, { Component, useRef, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation, useRelayEnvironment } from 'react-relay/hooks';
import { CommandsPageQuery } from './__generated__/CommandsPageQuery.graphql';

import Editor, { BeforeMount, OnMount } from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { languages } from 'monaco-editor/esm/vs/editor/editor.api';
import { stringify } from 'postcss';
import { CommandsPageUpdateMutation } from './__generated__/CommandsPageUpdateMutation.graphql';
import Button from '../../components/Button';
import { CommandsPageValidateMutation } from './__generated__/CommandsPageValidateMutation.graphql';

function CommandsPage() {
  const data = useLazyLoadQuery<CommandsPageQuery>(graphql`
    query CommandsPageQuery {
      me {
        username
        isAdmin
      }
      commandsDefinition {
        version
        definition
      }
    }
  `, {});

  let [errors, setErrors] = useState<string[]>([]);

  let [submitCommandsDefinition, submitCommandsDefinitionInflight] = useMutation<CommandsPageUpdateMutation>(
    graphql`
      mutation CommandsPageUpdateMutation($input: CreateCommandsDefinitionInput!) {
        createCommandsDefinition(input: $input) {
          commandsDefinition {
            id
            version
            definition
          }
          errors
        }
      }
    `
  );

  let [validateCommandsDefinition, validateCommandsDefinitionInflight] = useMutation<CommandsPageValidateMutation>(
    graphql`
      mutation CommandsPageValidateMutation($input: CompileCommandsDefinitionInput!) {
        compileCommandsDefinition(input: $input) {
          errors
          markers {
            startLineNumber
            endLineNumber
            startColumn
            endColumn
            message
          }
        }
      }
    `
  );

  const save = () => {
    submitCommandsDefinition({
      variables: {
        input: {
          oldVersion: data.commandsDefinition?.version || 0,
          definition: editorRef.current?.getValue() || "",
        }
      },
      onCompleted(response, errors) {
        if (response.createCommandsDefinition?.errors) {
          setErrors(response.createCommandsDefinition.errors.map(s => s));
        } else {
          setErrors([]);
        }
      }
    })
  }

  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);

  const handleEditorDidMount: OnMount = (editor, monaco) => {
    editorRef.current = editor;
  }

  if (!data.me || !data.me.isAdmin) {
    window.location.pathname = '/';
  }

  const config =
`when(command == "request" || "requests") {
  when (username == "exosnecko") {
    say "You know how to do this, you built the thing."
  }

  say "Want to request a song? $#{token_cost_dollars} or #{token_cost_bits} bits (or more) will get you a token. You also get a token when you share your Tier 2 or Tier 3 sub anniversary. You can browse the song list and use your tokens here: You can browse the song list and use your tokens here: https://www.onlyschlorps.com/"
}
`;

  const options: editor.IStandaloneEditorConstructionOptions = {
    minimap: {
      enabled: false,
    },
    autoIndent: "brackets",
  };

  const handleEditorWillMount: BeforeMount = (monaco) => {
    monaco.languages.register({ id: 'commandLanguage' });

    monaco.languages.setMonarchTokensProvider('commandLanguage', {
      keywords: [
        'when'
      ],
      classKeywords: [
        'Feature'
      ],
      regexpctl: /[(){}\[\]\$\^|\-*+?\.]/,
      regexpesc: /\\(?:[AzZbBdDfnrstvwWn0\\\/]|@regexpctl|c[A-Z]|x[0-9a-fA-F]{2}|u[0-9a-fA-F]{4})?/,
      tokenizer: {
        root: [
          {include: '@brackets'},
          [/[A-Z][\w$]*/, {
            cases: {
              '@classKeywords': 'keyword.class',
            }
          }],
          [/[a-z_$][\w$]*/, {
            cases: {
              '@keywords': 'keyword',
              '@default': 'identifier'
            }
          }],
          [/\/(?=(\\\/|[^\/\n])+\/)/, { token: 'regexp.delim', next: '@regexp' }],
          [/"/, 'string', '@string']
        ],
        brackets: [
          [/[{}()\[\]]/, '@brackets'],
        ],

        string: [
          [/[^\\`"#]+/, 'string'],
          [/#/, 'string.escape', '@interpolated'],
          [/"/, 'string', '@pop'],
        ],

        whitespace: [
          [/[ \t\r\n]+/, 'white'],
        ],

        regexp: [
          { include: '@regexcontrol' },
          [/[^\\\/]/, 'regexp'],
          ['/[ixmp]*', { token: 'regexp.delim' }, '@pop']
        ],

        regexcontrol: [
          [
            /(\{)(\d+(?:,\d*)?)(\})/,
            [
              '@brackets.regexp.escape.control',
              'regexp.escape.control',
              '@brackets.regexp.escape.control'
            ]
          ],
          [
            /(\[)(\^?)/,
            [
              '@brackets.regexp.escape.control',
              'regexp.escape.control',
              '@regexrange',
            ]
          ],
          [/(\()(\?[:=!])/, ['@brackets.regexp.escape.control', 'regexp.escape.control']],
          [/\(\?#/, { token: 'regexp.escape.control', next: '@regexpcomment' }],
          [/[()]/, '@brackets.regexp.escape.control'],
          [/@regexpctl/, 'regexp.escape.control'],
          [/\\$/, 'regexp.escape'],
          [/@regexpesc/, 'regexp.escape'],
          [/\\\./, 'regexp.invalid'],
          [/#/, 'regexp.escape', '@interpolated']
        ],

        regexrange: [
          [/-/, 'regexp.escape.control'],
          [/\^/, 'regexp.invalid'],
          [/\\$/, 'regexp.escape'],
          [/@regexpesc/, 'regexp.escape'],
          [/[^\]]/, 'regexp'],
          [/\]/, '@brackets.regexp.escape.control', '@pop']
        ],

        regexpcomment: [
          [/[^)]+/, 'comment'],
          [/\)/, { token: 'regexp.escape.control', next: '@pop' }]
        ],

        interpolated: [
          [/\$\w*/, 'global.constant', '@pop'],
          [/@\w*/, 'namespace.class.identifier', '@pop'],
          [/@@\w*/, 'namespace.instance.identifier', '@pop'],
          [
            /[{]/,
            {
              token: 'string.escape.curly',
              switchTo: '@interpolated_compound'
            }
          ],
          ['', '', '@pop'] // just a # is interpreted as a #
        ],

        // any code
        interpolated_compound: [
          [/[}]/, { token: 'string.escape.curly', next: '@pop' }],
          { include: '@root' }
        ],
      },
    });

    monaco.editor.onDidCreateModel((model) => {
      function validate() {
        const textToValidate = model.getValue();

        validateCommandsDefinition({
          variables: {
            input: {
              definition: textToValidate,
            },
          },
          onCompleted(response, errors) {
            if (response.compileCommandsDefinition) {
              monaco.editor.setModelMarkers(model, 'commandLanguage', response.compileCommandsDefinition.markers.map((m) => {
                return {
                  severity: monaco.MarkerSeverity.Error,
                  startLineNumber: m.startLineNumber,
                  startColumn: m.startColumn,
                  endLineNumber: m.endLineNumber,
                  endColumn: m.endColumn,
                  message: m.message,
                };
              }));
            }
          }
        })

        const markers = [{
          severity: monaco.MarkerSeverity.Error,
          startLineNumber: 1,
          startColumn: 2,
          endLineNumber: 1,
          endColumn: 3,
          message: 'hi there'
        }];

      }

      let handle: NodeJS.Timeout;

      model.onDidChangeContent(() => {
        clearTimeout(handle);
        handle = setTimeout(() => validate(), 500);
      });

      validate();
    });

    monaco.languages.setLanguageConfiguration('commandLanguage', {
      indentationRules: {
        increaseIndentPattern: /[{(\[]/,
        decreaseIndentPattern: /[})\]]/,
      }
    });

    monaco.editor.defineTheme('theme', {
      base: 'vs-dark',
      inherit: true,
      colors: {
      },
      rules: [
        { token: '', foreground: 'FFFFFF' },
        { token: 'string', foreground: '66b84b' },
        { token: 'string.escape', foreground: 'c7a43a' },
        { token: 'command', foreground: '7e92f7' },
        { token: 'command.colon', foreground: '9496a6' },
        { token: 'keyword', foreground: '9670b5' },
        { token: 'keyword.class', foreground: 'e6d693' },
        { token: 'identifier', foreground: '8cbade' },
        { token: 'regexp', foreground: 'c96b61' },
        { token: 'regexp.escape', foreground: '8cbade' },
      ]
    })
  }

  return <>
    <header className="py-4 text-white">
      <div className="max-w-7xl ">
        <h1 className="text-3xl leading-9 font-bold text-white">
          Commands v{data.commandsDefinition?.version}
        </h1>
      </div>
    </header>
    <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mt-5">
      {data && <Editor
        beforeMount={handleEditorWillMount}
        onMount={handleEditorDidMount}
        className="editor"
        language="commandLanguage"
        theme='theme'
        defaultValue={data.commandsDefinition?.definition}
        options={options}
      />}
      <Button onClick={save} className="mt-2" disabled={submitCommandsDefinitionInflight} label="Save"/>
      {
        (errors.length > 0) && <div>
          {errors.map((e, i) => {
            return <div key={i} className="font-mono whitespace-pre border-2 rounded m-1 border-red-700 bg-red-300 p-2">
              {e}
            </div>;
          })}
        </div>
      }
    </div>
  </>;
}

export default CommandsPage;