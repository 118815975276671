
import React, { Component, useLayoutEffect, useRef, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery, useMutation, useRelayEnvironment } from 'react-relay/hooks';
import moment from 'moment';
import Button from '../../components/Button';
import { Link, useMatch } from 'react-router-dom';
import { ConfigurableValuePageQuery } from './__generated__/ConfigurableValuePageQuery.graphql';
import clsx from 'clsx';
import { ConfigurableValuePageToggleMutation } from './__generated__/ConfigurableValuePageToggleMutation.graphql';
import { valueFromAST } from 'graphql';

function ConfigurableValueInputGroup({id, initialValue, label, submitValue}: {id: string, initialValue: string, label: string, submitValue: (id: string, value: string) => void}) {
  let ref = useRef<HTMLInputElement>(null);
  let [step, setStep] = useState(0);

  useLayoutEffect(() => {
    setStep(p => p + 1);
  }, []);

  return <>
    <label htmlFor="email" className="block text-sm font-medium text-gray-700">{label}</label>
    <div className={"mt-1 flex rounded-md shadow-sm"}>
      <div className="relative flex items-stretch flex-grow focus-within:z-10">
        <input ref={ref} type="text" name="email" id="email" className={clsx({"bg-yellow-100": ref.current?.value !== initialValue}, "focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300")} defaultValue={initialValue} onChange={() => setStep(p => p + 1)} />
      </div>
      <button onClick={() => submitValue(id, ref.current?.value || "")} className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
        <span>Save</span>
      </button>
    </div>
  </>;
}

function ConfigurableValuePage() {
  const [fetchKey, setFetchKey] = useState(0);

  const data = useLazyLoadQuery<ConfigurableValuePageQuery>(graphql`
    query ConfigurableValuePageQuery {
      me {
        isAdmin
      }
      configurableValues {
        id
        label
        value
      }
    }
  `, {}, {fetchKey: fetchKey, fetchPolicy: 'store-and-network'});

  let [configureValue, configureValueInFlight] = useMutation<ConfigurableValuePageToggleMutation>(
    graphql`
      mutation ConfigurableValuePageToggleMutation($input: ConfigureValueInput!) {
        configureValue(input: $input) {
          configurableValue {
            id
          }
          error
        }
      }
    `
  );

  if (!data.me || !data.me.isAdmin) {
    window.location.pathname = '/';
  }

  const submitValue = (id: string, value: string) => {
    configureValue({
      variables: {
        input: {
          id: id,
          value: value,
        },
      },
      onCompleted(response, errors) {
        setFetchKey(prev => prev + 1);

        if (response.configureValue?.error) {
          alert(response.configureValue.error);
        }
      }
    });
  };

  return <>
    <header className="py-4 text-white">
      <div className="max-w-7xl ">
        <h1 className="text-3xl leading-9 font-bold text-white">
          Configurable Values
        </h1>
      </div>
    </header>
    <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6 mt-5">
      {
        data.configurableValues.map((configurableValue, i) =>
          <div key={i} className="py-2">
            <ConfigurableValueInputGroup id={configurableValue.id} initialValue={configurableValue.value || ""} label={configurableValue.label || ""} submitValue={submitValue}/>
          </div>
        )
      }
    </div>
  </>;
}

export default ConfigurableValuePage;