/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateUploadUrlsInput = {
    names: Array<string>;
    clientMutationId?: string | null;
};
export type RequestPageUrlMutationVariables = {
    input: CreateUploadUrlsInput;
};
export type RequestPageUrlMutationResponse = {
    readonly createUploadUrls: {
        readonly urls: ReadonlyArray<{
            readonly url: string;
            readonly name: string;
            readonly sheetMusicId: string;
        }>;
    } | null;
};
export type RequestPageUrlMutation = {
    readonly response: RequestPageUrlMutationResponse;
    readonly variables: RequestPageUrlMutationVariables;
};



/*
mutation RequestPageUrlMutation(
  $input: CreateUploadUrlsInput!
) {
  createUploadUrls(input: $input) {
    urls {
      url
      name
      sheetMusicId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateUploadUrlsPayload",
    "kind": "LinkedField",
    "name": "createUploadUrls",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PresignedUploadInfo",
        "kind": "LinkedField",
        "name": "urls",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sheetMusicId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestPageUrlMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestPageUrlMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a5115365675ee32d75b341efc9a96855",
    "id": null,
    "metadata": {},
    "name": "RequestPageUrlMutation",
    "operationKind": "mutation",
    "text": "mutation RequestPageUrlMutation(\n  $input: CreateUploadUrlsInput!\n) {\n  createUploadUrls(input: $input) {\n    urls {\n      url\n      name\n      sheetMusicId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '68f648ce334beebdb0d58ad133707da3';
export default node;
